import React from 'react'
import { Col, Row } from 'react-bootstrap'
import '../../assets/css/dashboard.scss'
import totalCustomer from '../../assets/images/Dashboard/total-customer.svg'
import activeInactive from '../../assets/images/Dashboard/active-inactive.svg'
import customerUnderValidity from '../../assets/images/Dashboard/customer-under-validity.svg'
import customerLicenseExperation from '../../assets/images/Dashboard/customer-license-expiration.svg'
import Main from '../Main'
import Download from '../MainSection/Download/Download'
import BarChart from '../Graphs/BarChart'
import RelationshipTypeChart from '../Graphs/RelationshipTypeChart'
import FunnelChart from '../Graphs/FunnelChart'
import LicenceSpliteUpChart from '../Graphs/LicenceSpliteUpChart'
import AssignedModules from '../Graphs/AssignedModules'
import CustomerStatusChart from '../Graphs/CustomerStatusChart'
import { useNavigate } from 'react-router-dom'

function LicenceDashboard() {
    const labels = ['Active', 'Inactive', 'New'];
    const colors = [
        'rgba(58, 201, 119, 1)',  // Active
        'rgba(255, 94, 94, 1)', // Inactive
        'rgba(255, 159, 0, 1)', // New
    ];
    const data = [350, 50, 100];

    const funnelColors = ['rgba(0, 133, 163, 1)', 'rgba(3, 170, 207, 1)', 'rgba(6, 201, 244, 1)'];
    const funnelLabels = ['LC Available', 'LC Used', 'LC Provider'];
    const funnelData = [
        { value: 200 },
        { value: 150 },
        { value: 100 },
    ];
    const navigate = useNavigate();
    const handleBack = () => {
        navigate(-1);
    };

    const handleNavigation = () => {
        navigate('/usermanagement')
    }
    return (
        <div className='dashboard-container'>
            <Main>
                <div className='components-download-container'>
                    <Download moduleName="Licence Dashboard"
                        showBackButton={true}
                        showDashboardLibraryButton={true}
                        showSaveCustomViewButton={true}
                        showAnalyticsButton={false}
                        showDownloadDropdown={true}
                        onBackClick={handleBack}
                    />
                </div>
                <Row className='dashboard-cards-container'>
                    <Col md={3} className='p-0 pe-3'>
                        <div className='dashboard-cards' onClick={handleNavigation}>
                            <div className='dashboard-cards-counts'>
                                <p>Total Licence</p>
                                <b>200</b>
                            </div>
                            <div className='dashboard-cards-image'>
                                <img src={totalCustomer} alt="" />
                            </div>
                        </div>
                    </Col>
                    <Col md={3} className='p-0 pe-3'>
                        <div className='dashboard-cards' onClick={handleNavigation}>
                            <div className='dashboard-cards-counts'>
                                <p>Active / InActive</p>
                                <b>50 / 100</b>
                            </div>
                            <div className='dashboard-cards-image' style={{ backgroundColor: "rgba(255, 94, 94, 0.2)" }}>
                                <img src={activeInactive} alt="" />
                            </div>
                        </div>
                    </Col>
                    <Col md={3} className='p-0 pe-3'>
                        <div className='dashboard-cards' onClick={handleNavigation}>
                            <div className='dashboard-cards-counts'>
                                <p>Licence Under Validity</p>
                                <b>200</b>
                            </div>
                            <div className='dashboard-cards-image' style={{ backgroundColor: "rgba(255, 159, 0, 0.2)" }}>
                                <img src={customerUnderValidity} alt="" />
                            </div>
                        </div>
                    </Col>
                    <Col md={3} className='p-0'>
                        <div className='dashboard-cards' onClick={handleNavigation}>
                            <div className='dashboard-cards-counts'>
                                <p>License Expiration</p>
                                <b>20</b>
                            </div>
                            <div className='dashboard-cards-image' style={{ backgroundColor: "rgba(52, 58, 64, 0.2)" }}>
                                <img src={customerLicenseExperation} alt="" />
                            </div>
                        </div>
                    </Col>
                </Row>
                <Row className='dashboard-charts'>
                    <Col md={6} className='dashboard-charts-div p-0 '>
                        <div className='charts-container'>
                            <BarChart moduleName="Licence Details" />
                        </div>
                    </Col>
                    <Col md={3} className='dashboard-charts-div p-0 '>
                        <div className='charts-container'>
                            <CustomerStatusChart
                                moduleName="Licence Status"
                                labels={labels}
                                colors={colors}
                                data={data}
                                showYear={true}
                                showCompany={true}
                            />
                        </div>
                    </Col>
                    <Col md={3} className='dashboard-charts-div p-0 pe-4'>
                        <div style={{ height: "100%" }}>
                            <FunnelChart
                                moduleName="No of Licence" colors={funnelColors} labels={funnelLabels} data={funnelData}
                            />
                        </div>
                    </Col>
                </Row>
                <Row className='dashboard-charts pt-4 pb-4'>
                    <Col md={9} className='dashboard-charts-div p-0'>
                        <div className='charts-container'>
                            <LicenceSpliteUpChart />
                        </div>
                    </Col>

                    <Col md={3} className='dashboard-charts-div p-0 pe-4'>
                        <div style={{ height: "100%" }}>
                            <AssignedModules />
                        </div>
                    </Col>
                </Row>
            </Main>
        </div>
    )
}

export default LicenceDashboard

