import React, { useRef, useState } from 'react';
import { Col, Modal, Row, Form } from 'react-bootstrap';
import headQuarter from '../../assets/images/CustomerManagement/head-quarter.svg'
import customerImg from '../../assets/images/CustomerManagement/customer-img.svg'
import dotImg from '../../assets/images/CustomerManagement/dot-img.svg'
import businessUnit from '../../assets/images/CustomerManagement/business-unit.svg'
import branchInformation from '../../assets/images/CustomerManagement/branch-plant-information.svg'
import storageLocationImg from '../../assets/images/CustomerManagement/storage-location.svg'
import crossmark from '../../assets/images/CustomerManagement/crossmark.svg'
import Select from 'react-select';
import DatePicker from 'react-datepicker';
import calendar from '../../assets/images/Dashboard/calendar-icon.svg'
import SbCancelBtn, { SbAddBtn, SBPreviousBtn, SBSaveUpdateBtn } from '../Buttons/Buttons';

const contactOptions = [
  { value: '+91', label: '+91' },
  { value: '+1', label: '+1' },
  { value: '+44', label: '+44' },
];
const statusOptions = [
  { value: "Active", label: "Active" },
  { value: "Inactive", label: "Inactive" },
]
const AddCustomerModal = ({ open, handleClose }) => {
  const [customer, setCustomer] = useState({
    companyName: '',
    contactName: '',
    email: '',
    noOfLicense: '',
    contactNumber: '',
    classification: '',
    relationshipType: '',
    address: '',
    zipCode: '',
    startDate: '',
    endDate: '',
    assignedModules: '',
  });
  const [selectedOption, setSelectedOption] = useState({ value: "+91", label: "+91" });
  const [selectedStatus, setSelectedStatus] = useState(statusOptions[0]);

  const handleChangeStatus = (selectedStatus) => {
    setSelectedStatus(selectedStatus);
  }

  const handleChange = (e) => {
    const { name, value } = e.target;
    setCustomer((prevCustomer) => ({
      ...prevCustomer,
      [name]: value,
    }));
  };

  const [businessUnits, setBusinessUnits] = useState([]);

  const handleAddBusinessUnit = () => {
    setBusinessUnits(prevUnits => [...prevUnits, {}]); // Adding an empty object as a placeholder
  };
  const handleDeleteBusinessUnit = (index) => {
    setBusinessUnits((prevUnits) => prevUnits.filter((_, i) => i !== index));
  };
  const [branchPlant, setBranchPlant] = useState([]);

  const handleAddBranchPlant = () => {
    setBranchPlant(prevUnits => [...prevUnits, {}]); // Adding an empty object as a placeholder
  };

  const handleDeleteBranchPlantInformation = (index) => {
    setBranchPlant((prevUnits) => prevUnits.filter((_, i) => i !== index))
  }

  const [storageLocation, setStorageLocation] = useState([])
  const handleAddStorage = () => {
    setStorageLocation(prevUnits => [...prevUnits, {}]); // Adding an empty object as a placeholder
  };
  const handleDeleteStorageLocation = (index) => {
    setStorageLocation((prevUnits) => prevUnits.filter((_, i) => i !== index))
  }

  const [step, setStep] = useState(1); // 1 for initial step, 2 for business units

  const handleNextStep = () => {
    setStep(prevStep => (prevStep < 4 ? prevStep + 1 : prevStep)); // Move to next step if not on the last step
  };

  const handleBackStep = () => {
    setStep(prevStep => (prevStep > 1 ? prevStep - 1 : prevStep)); // Move to previous step if not on the first step
  };

  const fileInputRef = useRef(null);
  const [selectedImg, setSelectedImg] = useState(null); // State for the selected image

  const handleUploadClick = () => {
    fileInputRef.current.click();
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setSelectedImg(reader.result); // Update the state with the new image
      };
      reader.readAsDataURL(file); // Read the file as a data URL
    }
  };

  return (
    <Modal show={open} onHide={handleClose} className='add-customer-modal'>
      <Modal.Header closeButton>
        <Modal.Title>Add Customer</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Row className='add-customer-menus'>
          <Col
            className={`add-customer-menus-names ${step >= 1 ? 'step-active' : 'step-remaining'}`}
          >
            <img src={headQuarter} alt="" />
            <p>Head Quarters</p>

          </Col>
          <Col className='add-customer-dots'>
            <img src={dotImg} alt="" />
          </Col>
          <Col
            className={`add-customer-menus-names ${step >= 2 ? 'step-active' : step === 1 ? 'step-next' : 'step-remaining'}`}
          >
            <img src={businessUnit} alt="" />
            <p>Business Unit</p>
          </Col>
          <Col className='add-customer-dots'>
            <img src={dotImg} alt="" />
          </Col>
          <Col
            className={`add-customer-menus-names ${step >= 3 ? 'step-active' : step === 2 ? 'step-next' : 'step-remaining'}`}
          >
            <img src={branchInformation} alt="" />
            <p>Branch / Plant Information</p>
          </Col>
          <Col className='add-customer-dots'>
            <img src={dotImg} alt="" />
          </Col>
          <Col
            className={`add-customer-menus-names ${step >= 4 ? 'step-active' : step === 3 ? 'step-next' : 'step-remaining'}`}
          >
            <img src={storageLocationImg} alt="" />
            <p>Storage Location</p>
          </Col>
        </Row>
        {step === 1 && (
          <Row className='customer-form-fields'>
            <Col className='customer-form-fields-image-div' onClick={handleUploadClick}>
              <img src={selectedImg || customerImg} alt="" style={{ width: "100px", height: "100px" }} />
              <input
                type="file"
                hidden
                ref={fileInputRef}
                onChange={handleFileChange}
              />
              <p>Upload Image</p>
            </Col>
            <Col>
              <Row>
                <Col md={4}>
                  <div>
                    <label>Company Code</label>
                    <Form.Control type="text" />
                  </div>
                </Col>
                <Col md={4}>
                  <div>
                    <label>Company Name</label>
                    <Form.Control type="text" />
                  </div>
                </Col>
                <Col md={4}>
                  <div>
                    <label>Contact Name</label>
                    <Form.Control type="text" />
                  </div></Col>

              </Row>
              <Row>
                <Col md={4}>
                  <div>
                    <label>Email ID</label>
                    <Form.Control type="email" />
                  </div>
                </Col>
                <Col md={4}>
                  <div>
                    <label>No.of.Licence</label>
                    <Form.Control type="number" />
                  </div>
                </Col>
                <Col md={4}>
                  <div>
                    <label>Contact Number</label>
                    <div className='customer-contact-fields-div'>
                      <Select
                        // className='customer-contact-select '
                        className="react-select-container"
                        classNamePrefix="react-select"
                        value={selectedOption.value}
                        // onChange={handleChange}
                        options={contactOptions}
                        placeholder="+91"
                      />
                      <Form.Control type="email" />
                    </div>
                  </div>
                </Col>

              </Row>
              <Row>
                <Col md={4}>
                  <div>
                    <label>Classification</label>
                    <Select
                      className="react-select-container"
                      classNamePrefix="react-select"
                      // value={selectedOption}
                      onChange={handleChange}
                      // options={contactOptions}
                      placeholder="Select"
                    />
                  </div>
                </Col>
                <Col md={4}>
                  <div>
                    <label>Address</label>
                    <Form.Control type="text" />
                  </div>
                </Col>
                <Col md={4}>
                  <div>
                    <label>Zip Code</label>
                    <Form.Control type="number" />
                  </div>
                </Col>

              </Row>
              <Row>
                <Col md={4}>
                  <div>
                    <label>Relationship Type</label>
                    <Form.Control type="text" />
                  </div>
                </Col>
                <Col md={4}>
                  <div className='add-customer-calendar-container'>
                    <label>Start Date</label>
                    {/* <div className='add-customer-calendar-container'> */}
                    <DatePicker
                      className="select from-date"
                      id="date-range-picker"
                      dateFormat="dd/MM/yyyy"
                      showMonthDropdown
                      showYearDropdown
                      selectsRange
                      dropdownMode="select"
                      placeholderText='Start Date'
                      // startDate={fromDate}
                      // onChange={(data) => setSelectSearchDrop(data, "date")}
                      maxDate={new Date()}
                    />
                    <img src={calendar} alt="" />
                    {/* </div> */}
                  </div>
                </Col>
                <Col md={4}>
                  <div className='add-customer-calendar-container'>
                    <label>End Date</label>
                    {/* <div className='add-customer-calendar-container'> */}
                    <DatePicker
                      className="select from-date"
                      id="date-range-picker"
                      dateFormat="dd/MM/yyyy"
                      showMonthDropdown
                      showYearDropdown
                      selectsRange
                      dropdownMode="select"
                      placeholderText='End Date'
                      // startDate={fromDate}
                      // onChange={(data) => setSelectSearchDrop(data, "date")}
                      maxDate={new Date()}
                    />
                    <img src={calendar} alt="" />
                    {/* </div> */}
                  </div>
                </Col>

              </Row>
              <Row>
                <Col md={4}>
                  <div>
                    <label>Assigned Modules</label>
                    <Select
                      className="react-select-container"
                      classNamePrefix="react-select"
                      // value={selectedOption}
                      onChange={handleChange}
                      // options={contactOptions}
                      placeholder="Select"
                    />
                  </div>
                </Col>
                <Col md={4}>
                  <div>
                    <label>Website</label>
                    <Form.Control type="text" />
                  </div>
                </Col>
                <Col md={4}>
                  <label>Status</label>
                  <Select
                    className="react-select-container"
                    classNamePrefix="react-select"
                    value={selectedStatus.value && selectedStatus}
                    onChange={handleChangeStatus}
                    options={statusOptions}
                    placeholder="Select"
                  />
                </Col>
              </Row>
            </Col>
          </Row>
        )}
        {step === 2 && (
          <Row className='customer-form-fields'>
            <Col>
              <Row>
                <Col md={4}>
                  <div>
                    <label>Business Unit Code</label>
                    <Form.Control type="text" />
                  </div>
                </Col>
                <Col md={4}>
                  <div>
                    <label>Business Unit Name</label>
                    <Form.Control type="text" />
                  </div>
                </Col>
                <Col md={4}>
                  <div>
                    <label>Relationship Type</label>
                    <Form.Control type="text" />
                  </div></Col>

              </Row>
              <Row>
                <Col md={4}>
                  <div>
                    <label>Reporting To</label>
                    <Form.Control type="text" />
                  </div>
                </Col>
                <Col md={4}>
                  <label>Status</label>
                  <Select
                    className="react-select-container"
                    classNamePrefix="react-select"
                    value={selectedStatus.value && selectedStatus}
                    onChange={handleChangeStatus}
                    options={statusOptions}
                    placeholder="Select"
                  />
                </Col>
              </Row>
              {businessUnits.map((unit, index) => (
                <>
                  <hr className='mt-4' />
                  <Row>
                    <Col className='cross-delete-img-div'>
                      <img src={crossmark} alt="" className='cross-delete-img' onClick={() => handleDeleteBusinessUnit(index)} />
                    </Col>
                  </Row>
                  <Row>
                    <Col md={4}>
                      <div>
                        <label>Business Unit Code</label>
                        <Form.Control type="text" />
                      </div>
                    </Col>
                    <Col md={4}>
                      <div>
                        <label>Business Unit Name</label>
                        <Form.Control type="text" />
                      </div>
                    </Col>
                    <Col md={4}>
                      <div>
                        <label>Relationship Type</label>
                        <Form.Control type="text" />
                      </div></Col>

                  </Row>
                  <Row>
                    <Col md={4}>
                      <div>
                        <label>Reporting To</label>
                        <Form.Control type="text" />
                      </div>
                    </Col>
                    <Col md={4}>
                      <label>Status</label>
                      <Select
                        className="react-select-container"
                        classNamePrefix="react-select"
                        value={selectedStatus.value && selectedStatus}
                        onChange={handleChangeStatus}
                        options={statusOptions}
                        placeholder="Select"
                      />
                    </Col>
                  </Row>
                </>
              ))}
              <Row>
                <Col className='business-unit-btn'>
                  <SbAddBtn btnName="Add Business Unit" onClickEffect={handleAddBusinessUnit} />
                </Col>
              </Row>
            </Col>
          </Row>
        )}
        {step === 3 && (
          <Row className='customer-form-fields customer-branch-plant-form-fields'>
            <Col>
              <Row>
                <Col md={4}>
                  <div>
                    <label>Branch/Plant Code</label>
                    <Form.Control type="text" />
                  </div>
                </Col>
                <Col md={4}>
                  <div>
                    <label>Branch/Plant Name</label>
                    <Form.Control type="text" />
                  </div>
                </Col>
                <Col md={4}>
                  <div>
                    <label>Email</label>
                    <Form.Control type="email" />
                  </div></Col>

              </Row>
              <Row>
                <Col md={4}>
                  <div>
                    <label>Contact Number</label>
                    <div className='customer-contact-fields-div'>
                      <Select
                        // className='customer-contact-select '
                        className="react-select-container"
                        classNamePrefix="react-select"
                        value={selectedOption.value}
                        // onChange={handleChange}
                        options={contactOptions}
                        placeholder="Select"
                      />
                      <Form.Control type="email" />
                    </div>
                  </div>
                </Col>
                <Col md={4}>
                  <div>
                    <label>Reporting Type</label>
                    <Select
                      className="react-select-container"
                      classNamePrefix="react-select"
                      // value={selectedOption}
                      // onChange={handleChange}
                      // options={options}
                      placeholder="Select"
                    />
                  </div>
                </Col>
                <Col md={4}>
                  <div>
                    <label>Reporting To</label>
                    <Select
                      className="react-select-container"
                      classNamePrefix="react-select"
                      // value={selectedOption}
                      // onChange={handleChange}
                      // options={contactOptions}
                      placeholder="Select"
                    />
                  </div>
                </Col>

              </Row>
              <Row>
                <Col md={4}>
                  <div>
                    <label>No.of.Licenses</label>
                    <Form.Control type="number" />
                  </div>
                </Col>
                <Col md={4}>
                  <div>
                    <label>Relationship Type</label>
                    <Form.Control type="text" />
                  </div>
                </Col>
                <Col md={4}>
                  <div>
                    <label>Address</label>
                    <Select
                      className="react-select-container"
                      classNamePrefix="react-select"
                      // value={selectedOption}
                      // onChange={handleChange}
                      // options={contactOptions}
                      placeholder="Select"
                    />
                  </div>
                </Col>

              </Row>
              <Row>
                <Col md={4}>
                  <div>
                    <label>Zip Code</label>
                    <Form.Control type="number" />
                  </div>
                </Col>
                <Col md={4}>
                  <label>Status</label>
                  <Select
                    className="react-select-container"
                    classNamePrefix="react-select"
                    value={selectedStatus.value && selectedStatus}
                    onChange={handleChangeStatus}
                    options={statusOptions}
                    placeholder="Select"
                  />
                </Col>
              </Row>
              {/* <Row>
                <Col className='business-unit-btn'>
                  <SbAddBtn btnName="Add Branch / Plant"
                    onClickEffect={handleAddBranchPlant}
                  />
                </Col>
              </Row> */}

              {branchPlant.map((unit, index) => (
                <>
                  <hr className='mt-4' />
                  <Row>
                    <Col className='cross-delete-img-div'>
                      <img src={crossmark} alt="" className='cross-delete-img' onClick={() => handleDeleteBranchPlantInformation(index)} />
                    </Col>
                  </Row>
                  <Row className='customer-form-fields customer-branch-plant-form-fields'>
                    <Col>
                      <Row>
                        <Col md={4}>
                          <div>
                            <label>Branch/Plant Code</label>
                            <Form.Control type="text" />
                          </div>
                        </Col>
                        <Col md={4}>
                          <div>
                            <label>Branch/Plant Name</label>
                            <Form.Control type="text" />
                          </div>
                        </Col>
                        <Col md={4}>
                          <div>
                            <label>Email</label>
                            <Form.Control type="email" />
                          </div></Col>

                      </Row>
                      <Row>
                        <Col md={4}>
                          <div>
                            <label>Contact Number</label>
                            <div className='customer-contact-fields-div'>
                              <Select
                                // className='customer-contact-select '
                                className="react-select-container"
                                classNamePrefix="react-select"
                                value={selectedOption.value}
                                // onChange={handleChange}
                                options={contactOptions}
                                placeholder="Select"
                              />
                              <Form.Control type="email" />
                            </div>
                          </div>
                        </Col>
                        <Col md={4}>
                          <div>
                            <label>Reporting Type</label>
                            <Select
                              className="react-select-container"
                              classNamePrefix="react-select"
                              // value={selectedOption}
                              // onChange={handleChange}
                              // options={options}
                              placeholder="Select"
                            />
                          </div>
                        </Col>
                        <Col md={4}>
                          <div>
                            <label>Reporting To</label>
                            <Select
                              className="react-select-container"
                              classNamePrefix="react-select"
                              // value={selectedOption}
                              // onChange={handleChange}
                              // options={contactOptions}
                              placeholder="Select"
                            />
                          </div>
                        </Col>

                      </Row>
                      <Row>
                        <Col md={4}>
                          <div>
                            <label>No.of.Licenses</label>
                            <Select
                              className="react-select-container"
                              classNamePrefix="react-select"
                              // value={selectedOption}
                              // onChange={handleChange}
                              // options={contactOptions}
                              placeholder="Select"
                            />
                          </div>
                        </Col>
                        <Col md={4}>
                          <div>
                            <label>Relationship Type</label>
                            <Form.Control type="text" />
                          </div>
                        </Col>
                        <Col md={4}>
                          <div>
                            <label>Address</label>
                            <Select
                              className="react-select-container"
                              classNamePrefix="react-select"
                              // value={selectedOption}
                              // onChange={handleChange}
                              // options={contactOptions}
                              placeholder="Select"
                            />
                          </div>
                        </Col>

                      </Row>
                      <Row>
                        <Col md={4}>
                          <div>
                            <label>Zip Code</label>
                            <Select
                              className="react-select-container"
                              classNamePrefix="react-select"
                              // value={selectedOption}
                              // onChange={handleChange}
                              // options={contactOptions}
                              placeholder="Select"
                            />
                          </div>
                        </Col>
                        <Col md={4}>
                          <label>Status</label>
                          <Select
                            className="react-select-container"
                            classNamePrefix="react-select"
                            value={selectedStatus.value && selectedStatus}
                            onChange={handleChangeStatus}
                            options={statusOptions}
                            placeholder="Select"
                          />
                        </Col>
                      </Row>

                    </Col>

                  </Row>
                </>
              ))}
              <Row>
                <Col className='business-unit-btn'>
                  <SbAddBtn btnName="Add Branch / Plant"
                    onClickEffect={handleAddBranchPlant}
                  />
                </Col>
              </Row>
            </Col>
          </Row>
        )}
        {step === 4 && (
          <Row className='customer-form-fields'>
            <Col>
              <Row>
                <Col md={4}>
                  <div>
                    <label>Storage Location Code</label>
                    <Form.Control type="text" />
                  </div>
                </Col>
                <Col md={4}>
                  <div>
                    <label>Storage Location Name</label>
                    <Form.Control type="text" />
                  </div>
                </Col>
                <Col md={4}>
                  <div>
                    <label>Relationship Type</label>
                    <Form.Control type="text" />
                  </div></Col>

              </Row>
              <Row>
                <Col md={4}>
                  <div>
                    <label>Reporting Type</label>
                    <Form.Control type="text" />
                  </div>
                </Col>
                <Col md={4}>
                  <div>
                    <label>Reporting To</label>
                    <Form.Control type="text" />
                  </div>
                </Col>
                <Col md={4}>
                  <label>Status</label>
                  <Select
                    className="react-select-container"
                    classNamePrefix="react-select"
                    value={selectedStatus.value && selectedStatus}
                    onChange={handleChangeStatus}
                    options={statusOptions}
                    placeholder="Select"
                  />
                </Col>
              </Row>
              {/* <Row>
                <Col className='business-unit-btn'>
                  <SbAddBtn btnName="Add SLOC" onClickEffect={handleAddStorage} />
                </Col>
              </Row> */}
              {storageLocation.map((unit, index) => (
                <>
                  <hr className='mt-4' />
                  <Row>
                    <Col className='cross-delete-img-div'>
                      <img src={crossmark} alt="" className='cross-delete-img' onClick={() => handleDeleteStorageLocation(index)} />
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Row>
                        <Col md={4}>
                          <div>
                            <label>Storage Location Code</label>
                            <Form.Control type="text" />
                          </div>
                        </Col>
                        <Col md={4}>
                          <div>
                            <label>Storage Location Name</label>
                            <Form.Control type="text" />
                          </div>
                        </Col>
                        <Col md={4}>
                          <div>
                            <label>Relationship Type</label>
                            <Form.Control type="text" />
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col md={4}>
                          <div>
                            <label>Reporting Type</label>
                            <Form.Control type="text" />
                          </div>
                        </Col>
                        <Col md={4}>
                          <div>
                            <label>Reporting To</label>
                            <Form.Control type="text" />
                          </div>
                        </Col>
                        <Col md={4}>
                          <label>Status</label>
                          <Select
                            className="react-select-container"
                            classNamePrefix="react-select"
                            value={selectedStatus.value && selectedStatus}
                            onChange={handleChangeStatus}
                            options={statusOptions}
                            placeholder="Select"
                          />
                        </Col>
                      </Row>

                    </Col>
                  </Row>
                </>
              ))}
              <Row>
                <Col className='business-unit-btn'>
                  <SbAddBtn btnName="Add SLOC" onClickEffect={handleAddStorage} />
                </Col>
              </Row>
            </Col>

          </Row>

        )}
      </Modal.Body >
      <Modal.Footer>
        <SbCancelBtn btnName="Cancel" onClickEffect={handleClose} />
        {/* {step === 2 && <SBPreviousBtn btnName="Previous" onClickEffect={handleBackStep} />} */}
        {/* <SBSaveUpdateBtn btnName="Next" onClickEffect={handleNextStep} /> */}
        {step > 1 && <SBPreviousBtn btnName="Previous" onClickEffect={handleBackStep} />}
        {step < 4 ? <SBSaveUpdateBtn btnName="Next" onClickEffect={handleNextStep} /> : <SBSaveUpdateBtn btnName="Save" onClickEffect={handleNextStep} />}
      </Modal.Footer>
    </Modal >
  );
};

export default AddCustomerModal;





// AddCustomerModal.js
// import React, { useState } from 'react';
// import { Modal, Form, Row, Col } from 'react-bootstrap';
// import HeadQuarterForm from './HeadQuarterForm';
// import BusinessUnitForm from './BusinessUnitForm';
// import BranchPlantForm from './BranchPlantForm';
// import StorageLocationForm from './StorageLocationForm';
// import SbButton, { SBPreviousBtn, SBSaveUpdateBtn } from '../Buttons/Buttons';
// import headQuarter from '../../assets/images/CustomerManagement/headQuarter-img.svg'
// import dotImg from '../../assets/images/CustomerManagement/dot-img.svg'
// import businessUnit from '../../assets/images/CustomerManagement/businessunit.svg'
// import SbCancelBtn from '../Buttons/Buttons';

// const AddCustomerModal = ({ open, handleClose, customer, handleChange }) => {
//   const [currentStep, setCurrentStep] = useState(1);
//   const [businessUnits, setBusinessUnits] = useState([]);
//   const [branchPlant, setBranchPlant] = useState([]);
//   const [storageLocation, setStorageLocation] = useState([]);

//   const handleAddBusinessUnit = () => {
//     setBusinessUnits(prevUnits => [...prevUnits, {}]);
//   };

//   const handleAddBranchPlant = () => {
//     setBranchPlant(prevUnits => [...prevUnits, {}]);
//   };

//   const handleAddStorage = () => {
//     setStorageLocation(prevUnits => [...prevUnits, {}]);
//   };

//   const renderStepForm = () => {
//     switch (currentStep) {
//       case 1:
//         return <HeadQuarterForm customer={customer} handleChange={handleChange}  />;
//       case 2:
//         return <BusinessUnitForm businessUnits={businessUnits} handleAddBusinessUnit={handleAddBusinessUnit} />;
//       case 3:
//         return <BranchPlantForm branchPlant={branchPlant} handleAddBranchPlant={handleAddBranchPlant}/>;
//       case 4:
//         return <StorageLocationForm storageLocation={storageLocation} handleAddStorage={handleAddStorage} />;
//       default:
//         return null;
//     }
//   };

//   return (
//     <Modal show={open} onHide={handleClose} size="lg" centered className="add-customer-modal">
//       <Modal.Header closeButton>
//         <Modal.Title>Add Customer</Modal.Title>
//       </Modal.Header>
//       <Modal.Body>
//         <Row className='add-customer-menus'>
//           <Col className='add-customer-menus-names'>
//             <img src={headQuarter} alt="" />
//             <p>Head Quarters</p>
//           </Col>
//           <Col className='add-customer-dots'>
//             <img src={dotImg} alt="" />
//           </Col>
//           <Col className='add-customer-menus-names'>
//             <img src={businessUnit} alt="" />
//             <p>Business Unit</p>
//           </Col>
//           <Col className='add-customer-dots'>
//             <img src={dotImg} alt="" />
//           </Col>
//           <Col className='add-customer-menus-names'>
//             <img src={headQuarter} alt="" />
//             <p>Branch / Plant Information</p>
//           </Col>
//           <Col className='add-customer-dots'>
//             <img src={dotImg} alt="" />
//           </Col>
//           <Col className='add-customer-menus-names'>
//             <img src={headQuarter} alt="" />
//             <p>Storage Location</p>
//           </Col>
//         </Row>
//         <Form className="customer-form-container">
//           {renderStepForm()}
//         </Form>
//       </Modal.Body>
//       <Modal.Footer>
//         <div className="footer-section">
//           <div className="customer-modal-form-indicators">
//             {[1, 2, 3, 4].map(step => (
//               <div key={step} className={currentStep === step ? 'active' : ''}></div>
//             ))}
//           </div>
//           <div className="modal-footer-buttons">
//             {currentStep === 1 && (
//               <>
//                 <SbCancelBtn btnName="Cancel" onClickEffect={handleClose} />
//                 <SBSaveUpdateBtn btnName="Next" onClickEffect={() => setCurrentStep(currentStep + 1)} />
//               </>
//             )}
//             {currentStep === 2 && (
//               <>
//                 <SbCancelBtn btnName="Cancel" onClickEffect={handleClose} />
//                 <SBPreviousBtn btnName="Previous" btnType="back" onClickEffect={() => setCurrentStep(currentStep - 1)} />
//                 <SBSaveUpdateBtn btnName="Next" btnType="next" onClickEffect={() => setCurrentStep(currentStep + 1)} />
//               </>
//             )}
//             {currentStep === 3 && (
//               <>
//                 <SbCancelBtn btnName="Cancel" onClickEffect={handleClose} />
//                 <SBPreviousBtn btnName="Previous" btnType="back" onClickEffect={() => setCurrentStep(currentStep - 1)} />
//                 <SBSaveUpdateBtn btnName="Next" btnType="next" onClickEffect={() => setCurrentStep(currentStep + 1)} />
//               </>
//             )}
//             {currentStep === 4 && (
//               <>
//                 <SbCancelBtn btnName="Cancel" onClickEffect={handleClose} />
//                 <SBPreviousBtn btnName="Previous" btnType="back" onClickEffect={() => setCurrentStep(currentStep - 1)} />
//                 <SBSaveUpdateBtn btnName="Save" btnType="save" onClickEffect={() => {/* Save logic here */ }} />
//               </>
//             )}
//           </div>
//         </div>
//       </Modal.Footer>
//     </Modal>
//   );
// };

// export default AddCustomerModal;

