import React from 'react';
import { Grid, Card, CardContent, Typography } from '@mui/material';
import Main from '../Main';
import rolesImg from '../../assets/images/Settings/rolesImg.svg'
import department from '../../assets/images/Settings/department.svg'
import designation from '../../assets/images/Settings/designation.svg'
import customerClassification from '../../assets/images/Settings/customerClassification.svg'
import relationshipType from '../../assets/images/Settings/relationshipType.svg'
import issueType from '../../assets/images/Settings/issueType.svg'
import menubar from '../../assets/images/Settings/menubar.svg'
import holiday from '../../assets/images/Settings/holiday.svg'
import customField from '../../assets/images/Settings/customField.svg'
import wqRules from '../../assets/images/Settings/wqRules.svg'
import functionalApproval from '../../assets/images/Settings/functionalApproval.svg'
import financialApproval from '../../assets/images/Settings/financialApproval.svg'
import complaintsApproval from '../../assets/images/Settings/complaintsApproval.svg'
import escalationApproval from '../../assets/images/Settings/escalationApproval.svg'
import leaveApproval from '../../assets/images/Settings/leaveApproval.svg'
import slaCategories from '../../assets/images/Settings/slaCategories.svg'
import organizationChart from '../../assets/images/Settings/organizationChart.svg'
import systemNumbering from '../../assets/images/Settings/systemNumbering.svg'
import '../../assets/css/Settings.scss'
import { Link, useNavigate } from 'react-router-dom';
import Download from '../MainSection/Download/Download';

const items = [
    { icon: <img src={rolesImg} alt="" />, title: 'Roles', content: 'ContentContentContentContent', path: '/settings/roles' },
    { icon: <img src={department} alt="" />, title: 'Department', content: 'ContentContentContentContent', path: '/settings/department' },
    { icon: <img src={designation} alt="" />, title: 'Job Title / Designation', content: 'ContentContentContentContent', path: '/settings/designation' },
    { icon: <img src={customerClassification} alt="" />, title: 'Customer Classification', content: 'ContentContentContentContent', path: '/settings/customerClassification' },
    { icon: <img src={relationshipType} alt="" />, title: 'Relationship Type', content: 'ContentContentContentContent', path: '/settings/relationshipType' },
    { icon: <img src={issueType} alt="" />, title: 'Issue Type', content: 'ContentContentContentContent', path: '/settings/issueType' },
    { icon: <img src={menubar} alt="" />, title: 'Menu Bar Enable / Disable', content: 'ContentContentContentContent', path: '/settings/moduleMangement' },
    { icon: <img src={holiday} alt="" />, title: 'Leave Policy & Holiday', content: 'ContentContentContentContent', path: '/settings/holidays' },
    { icon: <img src={customField} alt="" />, title: 'Custom Field', content: 'ContentContentContentContent', path: '/settings/customFields' },
    { icon: <img src={wqRules} alt="" />, title: 'WQ Rules', content: 'ContentContentContentContent', path: '/settings/wqRules' },
    { icon: <img src={functionalApproval} alt="" />, title: 'Functional Approval', content: 'ContentContentContentContent', path: '/settings/functionalApproval' },
    { icon: <img src={financialApproval} alt="" />, title: 'Financial Approval', content: 'ContentContentContentContent', path: '/settings/financialApproval' },
    { icon: <img src={complaintsApproval} alt="" />, title: 'Complaints Approval', content: 'ContentContentContentContent', path: '/settings/complaintsApproval' },
    { icon: <img src={escalationApproval} alt="" />, title: 'Escalation Approval', content: 'ContentContentContentContent', path: '/settings/escalationApproval' },
    { icon: <img src={leaveApproval} alt="" />, title: 'Leave Approval', content: 'ContentContentContentContent', path: '/settings/leaveApproval' },
    { icon: <img src={slaCategories} alt="" />, title: 'SLA Categories', content: 'ContentContentContentContent', path: '/settings/slaCategories' },
    { icon: <img src={organizationChart} alt="" />, title: 'Organization Chart', content: 'ContentContentContentContent', path: '/settings/organizationChart' },
    { icon: <img src={systemNumbering} alt="" />, title: 'System Numbering', content: 'ContentContentContentContent', path: '/settings/systemNumbering' },
];

const Settings = () => {
    const navigate = useNavigate();
    const handleBack = () => {
        navigate(-1);
    };
    return (
        <Main>
            <div className='components-download-container'>
                <Download
                    showBackButton={true}
                    moduleName="Settings"
                    onBackClick={handleBack}
                />
            </div>
            <Grid container spacing={3} style={{ padding: '20px' }}>
                {items.map((item, index) => (
                    <Grid item xs={12} sm={6} md={4} lg={3} key={index} style={{ paddingLeft: '25px' }}>
                        <Link to={item.path} style={{ textDecoration: 'none' }}>
                            <Card className='settings-card-container'>
                                <div className='settings-card-image'>
                                    {item.icon}
                                </div>
                                <CardContent className='settings-text-container'>
                                    <Typography variant="h6">{item.title}</Typography>
                                    <Typography variant="body2" color="textSecondary">
                                        {item.content}
                                    </Typography>
                                </CardContent>
                            </Card>
                        </Link>
                    </Grid>
                ))}
            </Grid>
        </Main>
    );
};

export default Settings;
