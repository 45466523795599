import React, { useState } from 'react'
import Main from '../Main'
import { Col, Row } from 'react-bootstrap'
import '../../assets/css/dashboard.scss'
import totalCustomer from '../../assets/images/Dashboard/total-customer.svg'
import activeInactive from '../../assets/images/Dashboard/active-inactive.svg'
import customerUnderValidity from '../../assets/images/Dashboard/customer-under-validity.svg'
import customerLicenseExperation from '../../assets/images/Dashboard/customer-license-expiration.svg'
import Download from '../MainSection/Download/Download'
import BarChart from '../Graphs/BarChart'
import RelationshipTypeChart from '../Graphs/RelationshipTypeChart'
import FunnelChart from '../Graphs/FunnelChart'
import LicenceSpliteUpChart from '../Graphs/LicenceSpliteUpChart'
import CustomerStatusChart from '../Graphs/CustomerStatusChart'
import AssignedModules from '../Graphs/AssignedModules'
import AttendanceStatusChart from '../Graphs/AttendanceStatusChart'
import { useNavigate } from 'react-router-dom'

function ComplaintEscalationDashboard() {
    const labels = ['Hign', 'Medium', 'Low'];
    const colors = [
        'rgba(255, 94, 94, 1)',  // High
        'rgba(255, 159, 0, 1)',  // Medium
        'rgba(58, 201, 119, 1)', // Low
    ];
    const data = [25, 20, 13];

    const [ticketType, setTicketType] = useState('All');
    const [companyName, setCompanyName] = useState('Company Name');
    const [year, setYear] = useState('2024');

    const ComplaintLabels = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    const BranchLabels = ['Branch1', 'Branch2', 'Branch3', 'Branch4'];
    const datasets = [
        {
            type: 'bar',
            label: 'Pending',
            data: [30, 40, 30, 40, 30, 40, 30, 40, 30, 40, 30, 40],
            backgroundColor: 'rgba(240, 207, 43, 1)', // Yellow
            borderColor: 'rgba(240, 207, 43, 1)',
            borderWidth: 1,
            borderRadius: 4,
            barThickness: 8,
            borderSkipped: false,
        },
        {
            type: 'bar',
            label: 'Inprogress',
            data: [20, 30, 20, 30, 20, 30, 20, 30, 20, 30, 20, 30],
            backgroundColor: 'rgba(255, 94, 0, 1)', // Orange
            borderColor: 'rgba(255, 94, 0, 1)',
            borderWidth: 1,
            borderRadius: 4,
            barThickness: 8,
            borderSkipped: false,
        },
        {
            type: 'line',
            label: 'Resolved',
            data: [10, 30, 20, 30, 25, 30, 20, 25, 30, 26, 25, 30],
            backgroundColor: (context) => {
                const chart = context.chart;
                const ctx = chart.ctx;
                const gradient = ctx.createLinearGradient(0, 0, 0, chart.height);
                gradient.addColorStop(0, 'rgba(58, 201, 119, 0.2)');
                gradient.addColorStop(1, 'rgba(58, 201, 119, 0)');
                return gradient;
            },
            borderColor: 'rgba(58, 201, 119, 1)',
            borderWidth: 2,
            fill: true,
            tension: 0.4,
        },
        {
            type: 'line',
            label: 'Reopen',
            data: [15, 35, 28, 35, 29, 33, 28, 29, 35, 20, 30, 35],
            backgroundColor: (context) => {
                const chart = context.chart;
                const ctx = chart.ctx;
                const gradient = ctx.createLinearGradient(0, 0, 0, chart.height);
                gradient.addColorStop(0, 'rgba(21, 104, 225, 0.2)');
                gradient.addColorStop(1, 'rgba(21, 104, 225, 0)');
                return gradient;
            },
            borderColor: 'rgba(21, 104, 225, 1)',
            borderWidth: 2,
            fill: true,
            tension: 0.4,
        },
        {
            type: 'line',
            label: 'Cancel',
            data: [10, 15, 25, 15, 20, 13, 21, 15, 25, 20, 24, 30],
            borderColor: 'rgba(255, 94, 94, 1)', // Red
            borderWidth: 2,
            borderDash: [5, 5], // Dotted line style
            tension: 0.4,
        },
    ];

    const ticketDetailsDatasets = [
        {
            type: 'bar',
            label: 'Complaints',
            data: [100, 80, 100, 80, 100, 80, 100, 80, 100, 80, 100, 80],
            backgroundColor: 'rgba(154, 56, 56, 1)', // Dark Red
            borderColor: 'rgba(154, 56, 56, 1)',
            borderWidth: 1,
            borderRadius: 4,
            barThickness: 8,
            borderSkipped: false,
        },
        {
            type: 'bar',
            label: 'Escalations',
            data: [60, 40, 50, 60, 30, 40, 50, 45, 55, 50, 60, 60],
            backgroundColor: 'rgba(255, 94, 94, 1)', // Bright Red
            borderColor: 'rgba(255, 94, 94, 1)',
            borderWidth: 1,
            borderRadius: 4,
            barThickness: 8,
            borderSkipped: false,
        }
    ];

    const chartOptions = {
        responsive: true,
        plugins: {
            tooltip: {
                callbacks: {
                    label: function (context) {
                        let label = context.dataset.label || '';
                        if (label) {
                            label += ': ';
                        }
                        if (context.parsed.y !== null) {
                            label += `${context.parsed.y}`;
                        }
                        return label;
                    }
                }
            }
        },
        scales: {
            x: {
                ticks: {
                    stepSize: 25,
                    callback: function (value) {
                        if (value === 0 || value === 25 || value === 50 || value === 75 || value === 100) {
                            return value;
                        }
                    }
                }
            },
            y: {
                beginAtZero: true
            }
        }
    };
    const dropdownOptions = {
        user: {
            default: 'Ticket Type',
            items: [
                { value: 'Escalation', label: 'Escalation' },
            ],
        },
        company: {
            default: 'Company Name',
            items: [
                { value: 'Company Name', label: 'Company Name' },
                { value: 'Other Company', label: 'Other Company' },
            ],
        },

        year: {
            default: 'Year',
            items: [
                { value: '2023', label: '2023' },
                { value: '2024', label: '2024' },
            ],
        },
    };

    const branchDropdownOptions = {
        company: {
            default: 'Company Name',
            items: [
                { value: 'Company Name', label: 'Company Name' },
                { value: 'Other Company', label: 'Other Company' },
            ],
        },

        year: {
            default: 'Year',
            items: [
                { value: '2023', label: '2023' },
                { value: '2024', label: '2024' },
            ],
        },
    };

    const funnelColors = ['rgba(0, 133, 163, 1)', 'rgba(3, 170, 207, 1)', 'rgba(6, 201, 244, 1)'];
    const funnelLabels = ['Service', 'Support', 'Technical'];
    const funnelData = [
        { value: 200 },
        { value: 150 },
        { value: 100 },
    ];
    const navigate = useNavigate();
    const handleBack = () => {
        navigate(-1);
    };

    const handleNavigation = () => {
        navigate('/complaint');
    }

    return (
        <div className='dashboard-container'>
            <Main>
                <div className='components-download-container'>
                    <Download moduleName="Complaint / Escalation Dashboard"
                        showBackButton={true}
                        showDashboardLibraryButton={true}
                        showSaveCustomViewButton={true}
                        showAnalyticsButton={false}
                        showDownloadDropdown={true}
                        onBackClick={handleBack}
                    />
                </div>
                <Row className='dashboard-cards-container'>
                    <Col className='p-0'>
                        <div className='dashboard-cards' onClick={handleNavigation}>
                            <div className='dashboard-cards-counts'>
                                <p>Total Complaints</p>
                                <b>200</b>
                            </div>
                            <div className='dashboard-cards-image'>
                                <img src={totalCustomer} alt="" />
                            </div>
                        </div>
                    </Col>
                    <Col className='pe-0'>
                        <div className='dashboard-cards' onClick={handleNavigation}>
                            <div className='dashboard-cards-counts'>
                                <p>Total Escalations</p>
                                <b>200</b>
                            </div>
                            <div className='dashboard-cards-image' style={{ backgroundColor: "rgba(255, 94, 94, 0.2)" }}>
                                <img src={activeInactive} alt="" />
                            </div>
                        </div>
                    </Col>
                    <Col className='pe-0'>
                        <div className='dashboard-cards' onClick={handleNavigation}>
                            <div className='dashboard-cards-counts'>
                                <p>Total Pending</p>
                                <b>200</b>
                            </div>
                            <div className='dashboard-cards-image' style={{ backgroundColor: "rgba(255, 159, 0, 0.2)" }}>
                                <img src={customerUnderValidity} alt="" />
                            </div>
                        </div>
                    </Col>
                    <Col className='pe-0'>
                        <div className='dashboard-cards' onClick={handleNavigation}>
                            <div className='dashboard-cards-counts'>
                                <p>Total Inprocess</p>
                                <b>200</b>
                            </div>
                            <div className='dashboard-cards-image' style={{ backgroundColor: "rgba(52, 58, 64, 0.2)" }}>
                                <img src={customerLicenseExperation} alt="" />
                            </div>
                        </div>
                    </Col>
                    <Col className='pe-0'>
                        <div className='dashboard-cards' onClick={handleNavigation}>
                            <div className='dashboard-cards-counts'>
                                <p>Total Resolved</p>
                                <b>200</b>
                            </div>
                            <div className='dashboard-cards-image' style={{ backgroundColor: "rgba(52, 58, 64, 0.2)" }}>
                                <img src={customerLicenseExperation} alt="" />
                            </div>
                        </div>
                    </Col>
                </Row>
                <Row className='dashboard-charts'>
                    <Col md={6} className='dashboard-charts-div p-0 '>
                        <div className='charts-container'>
                            <AttendanceStatusChart
                                moduleName="Ticket Details"
                                labels={ComplaintLabels}
                                datasets={ticketDetailsDatasets}
                                dropdownOptions={branchDropdownOptions}
                                defaultSelection={{ company: 'Company Name', year: 'Year' }}
                            />
                        </div>
                    </Col>
                    <Col md={3} className='dashboard-charts-div p-0 '>
                        <div className='charts-container'>
                            <RelationshipTypeChart
                                moduleName="Priority"
                                labels={labels}
                                colors={colors}
                                data={data}
                                showYear={true}
                                showCompany={true}
                            />
                        </div>
                    </Col>
                    <Col md={3} className='dashboard-charts-div p-0 pe-4'>
                        <div style={{ height: "100%" }}>
                            <FunnelChart
                                moduleName="Issue" colors={funnelColors} labels={funnelLabels} data={funnelData}
                            />
                        </div>
                    </Col>
                </Row>
                <Row className='dashboard-charts pt-4 pb-4'>
                    <Col md={6} className='dashboard-charts-div p-0'>
                        <div className='charts-container'>
                            <AttendanceStatusChart
                                moduleName="Status"
                                labels={ComplaintLabels}
                                datasets={datasets}
                                dropdownOptions={dropdownOptions}
                                defaultSelection={{ ticketType: 'Ticket Type', company: 'Company Name', year: 'Year' }}
                            />
                        </div>
                    </Col>
                    <Col md={6} className='dashboard-charts-div p-0 pe-2'>
                        <div className='charts-container'>
                            <AttendanceStatusChart
                                moduleName="Branch"
                                labels={BranchLabels}
                                datasets={datasets}
                                dropdownOptions={branchDropdownOptions}
                                defaultSelection={{ company: 'Company Name', year: 'Year' }}
                            />
                        </div>
                    </Col>
                </Row>
            </Main>
        </div>
    )
}

export default ComplaintEscalationDashboard

