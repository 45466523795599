import React, { useState } from "react";
import { Col, Form, Modal, Row } from "react-bootstrap";
import SbCancelBtn, { SBSaveUpdateBtn } from "../../Buttons/Buttons";
import Select from 'react-select';

const enablingOptions = [
    { value: "Active", label: "Active" },
    { value: "Inactive", label: "Inactive" }
]

const AddLeavePolicy = (props) => {
    const [selectStatus, setSelectStatus] = useState(enablingOptions[0]); // Default to "Enable"

    const handleStatus = (selectedOption) => {
        setSelectStatus(selectedOption);
    };

    return (
        <>
            <Modal show={props.open} onHide={props.handleCloseModal} className='add-customer-modal add-role-modal'>
                <Modal.Header closeButton>
                    <Modal.Title>Add Leave Policy</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row className='customer-form-fields'>
                        <Col>
                            <Row>
                                <Col md={4}>
                                    <div>
                                        <label> Level Type Name</label>
                                        <Form.Control type="text" />
                                    </div>
                                </Col>
                                <Col md={4}>
                                    <div>
                                        <label> Short Name</label>
                                        <Form.Control type="text" />
                                    </div>
                                </Col>
                                <Col md={4}>
                                    <div>
                                        <label>Max no of Days</label>
                                        <Select
                                            className="react-select-container"
                                            classNamePrefix="react-select"
                                            // options={enablingOptions}
                                            placeholder="Select"
                                        />
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={12}>
                                    <div>
                                        <label>Description</label>
                                        <Form.Control as="textarea" rows="2" />
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={4}>
                                    <div>
                                        <label>Country</label>
                                        <Select
                                            className="react-select-container"
                                            classNamePrefix="react-select"
                                            // options={enablingOptions}
                                            placeholder="Select"
                                        />
                                    </div>
                                </Col>
                                <Col md={4}>
                                    <div>
                                        <label>Allow Half Day Leave</label>
                                        <Select
                                            className="react-select-container"
                                            classNamePrefix="react-select"
                                            // options={enablingOptions}
                                            placeholder="Select"
                                        />
                                    </div>
                                </Col>
                                <Col md={4}>
                                    <div>
                                        <label>Is Paid Leave</label>
                                        <Select
                                            className="react-select-container"
                                            classNamePrefix="react-select"
                                            // options={enablingOptions}
                                            placeholder="Select"
                                        />
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={4}>
                                    <div>
                                        <label>Is Cancellable</label>
                                        <Select
                                            className="react-select-container"
                                            classNamePrefix="react-select"
                                            // options={enablingOptions}
                                            placeholder="Select"
                                        />
                                    </div>
                                </Col>
                                <Col md={4}>
                                    <div>
                                        <label>Auto Approve</label>
                                        <Select
                                            className="react-select-container"
                                            classNamePrefix="react-select"
                                            // options={enablingOptions}
                                            placeholder="Select"
                                        />
                                    </div>
                                </Col>
                                <Col md={4}>
                                    <div>
                                        <label>Notify Reporting</label>
                                        <Select
                                            className="react-select-container"
                                            classNamePrefix="react-select"
                                            // options={enablingOptions}
                                            placeholder="Select"
                                        />
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={4}>
                                    <div>
                                        <label>Notify User</label>
                                        <Select
                                            className="react-select-container"
                                            classNamePrefix="react-select"
                                            // options={enablingOptions}
                                            placeholder="Select"
                                        />
                                    </div>
                                </Col>
                                <Col md={4}>
                                    <div>
                                        <label>Exclude Weekend</label>
                                        <Select
                                            className="react-select-container"
                                            classNamePrefix="react-select"
                                            // options={enablingOptions}
                                            placeholder="Select"
                                        />
                                    </div>
                                </Col>
                                <Col md={4}>
                                    <div>
                                        <label>Exclude Holidays</label>
                                        <Select
                                            className="react-select-container"
                                            classNamePrefix="react-select"
                                            // options={enablingOptions}
                                            placeholder="Select"
                                        />
                                    </div>
                                </Col>

                            </Row>
                            <Row>
                                <Col md={4}>
                                    <div>
                                        <label>Allow Attachment</label>
                                        <Select
                                            className="react-select-container"
                                            classNamePrefix="react-select"
                                            // options={enablingOptions}
                                            placeholder="Select"
                                        />
                                    </div>
                                </Col>
                                <Col md={4}>
                                    <div>
                                        <label>Allow Carryforward</label>
                                        <Select
                                            className="react-select-container"
                                            classNamePrefix="react-select"
                                            // options={enablingOptions}
                                            placeholder="Select"
                                        />
                                    </div>
                                </Col>
                                <Col md={4}>
                                    <div>
                                        <label>Carryforward Expiry (Months)</label>
                                        <Select
                                            className="react-select-container"
                                            classNamePrefix="react-select"
                                            // options={enablingOptions}
                                            placeholder="Select"
                                        />
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={4}>
                                    <div>
                                        <label>Carryforword Expiry Notification</label>
                                        <Select
                                            className="react-select-container"
                                            classNamePrefix="react-select"
                                            // options={enablingOptions}
                                            placeholder="Select"
                                        />
                                    </div>
                                </Col>
                                <Col md={4}>
                                    <div>
                                        <label>Allow PrevDays Leave</label>
                                        <Select
                                            className="react-select-container"
                                            classNamePrefix="react-select"
                                            // options={enablingOptions}
                                            placeholder="Select"
                                        />
                                    </div>
                                </Col>
                                <Col md={4}>
                                    <div>
                                        <label>Req Before Days</label>
                                        <Form.Control type="text" />
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={4}>
                                    <div>
                                        <label>Attachment Req Days</label>
                                        <Form.Control type="text" />
                                    </div>
                                </Col>
                                <Col md={4}>
                                    <div>
                                        <label>Max Carry Forward</label>
                                        <Form.Control type="text" />
                                    </div>
                                </Col>
                                <Col md={4}>
                                    <div>
                                        <label>Status</label>
                                        <Select
                                            className="react-select-container"
                                            classNamePrefix="react-select"
                                            options={enablingOptions}
                                            placeholder="Select"
                                            value={selectStatus}
                                            onChange={handleStatus}
                                        />
                                    </div>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Modal.Body>
                <Modal.Footer>
                    <SbCancelBtn btnName="Cancel" onClickEffect={props.handleCloseModal} />
                    <SBSaveUpdateBtn btnName="Submit" onClickEffect={props.handleNextStep} />
                </Modal.Footer>
            </Modal>
        </>
    );
}

export default AddLeavePolicy;
