import React, { useEffect, useState } from 'react';
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    IconButton,
    Menu,
    MenuItem,
    TablePagination
} from '@mui/material';
import SortIcon from '../../assets/images/Dashboard/chevron-down.svg';
import ascToDesc from '../../assets/images/CustomerManagement/ascToDesc.svg';
import descToAsc from '../../assets/images/CustomerManagement/descToAsc.svg';
import SbActionDropdownButton from '../Buttons/SbActionDropdownButton';
import addIcon from '../../assets/images/Dashboard/blackAddImg.svg'
import Pagination from '../PaginationComponent';
import PaginationComponent from '../PaginationComponent';
import { SortableContainer, SortableElement } from 'react-sortable-hoc';

const data = [
    {
        id: 1001,
        name: 'Karthik karthik',
        emailId: 'Karthikkarthik@gmail.com',
        address: 'Saraswathipura, Nandini Layout, Bengaluru, Karnataka 560096',
        contact: '9999999999',
        country: 'Country1',
        role: 'RoleROle',
        assignedModules: 'Modules Accessing',
        reportingTo: 'Vijaykumar',
        status: 'Active',
        sloc: '5 sloc'
    },
    {
        id: 1002,
        name: 'Karthik karthik',
        emailId: 'Karthikkarthik@gmail.com',
        address: 'Saraswathipura, Nandini Layout, Bengaluru, Karnataka 560096',
        contact: '9999999999',
        country: 'Country1',
        role: 'RoleROle',
        assignedModules: 'Modules Accessing',
        reportingTo: 'Vijaykumar',
        status: 'Active',
        sloc: ' sloc'
    },
    {
        id: 1003,
        name: 'Karthik karthik',
        emailId: 'Karthikkarthik@gmail.com',
        address: 'Saraswathipura, Nandini Layout, Bengaluru, Karnataka 560096',
        contact: '9999999999',
        country: 'Country1',
        role: 'RoleROle',
        assignedModules: 'Modules Accessing',
        reportingTo: 'Vijaykumar',
        status: 'Active',
        sloc: 'sloc'
    },
    {
        id: 1004,
        name: 'Karthik karthik',
        emailId: 'Karthikkarthik@gmail.com',
        address: 'Saraswathipura, Nandini Layout, Bengaluru, Karnataka 560096',
        contact: '9999999999',
        country: 'Country1',
        role: 'RoleROle',
        assignedModules: 'Modules Accessing',
        reportingTo: 'Vijaykumar',
        status: 'In-Active',
        sloc: 'sloc 2'
    },
    {
        id: 1005,
        name: 'Karthik karthik',
        emailId: 'Karthikkarthik@gmail.com',
        address: 'Saraswathipura, Nandini Layout, Bengaluru, Karnataka 560096',
        contact: '9999999999',
        country: 'Country1',
        role: 'RoleROle',
        assignedModules: 'Modules Accessing',
        reportingTo: 'Vijaykumar',
        status: 'Active',
        sloc: 'sloc'
    },
    {
        id: 1006,
        name: 'Karthik karthik',
        emailId: 'Karthikkarthik@gmail.com',
        address: 'Saraswathipura, Nandini Layout, Bengaluru, Karnataka 560096',
        contact: '9999999999',
        country: 'Country1',
        role: 'RoleROle',
        assignedModules: 'Modules Accessing',
        reportingTo: 'Vijaykumar',
        status: 'Active',
        sloc: 'sloc'
    },
    {
        id: 1007,
        name: 'Karthik karthik',
        emailId: 'Karthikkarthik@gmail.com',
        address: 'Saraswathipura, Nandini Layout, Bengaluru, Karnataka 560096',
        contact: '9999999999',
        country: 'Country1',
        role: 'RoleROle',
        assignedModules: 'Modules Accessing',
        reportingTo: 'Vijaykumar',
        status: 'In-Active',
        sloc: '3 sloc'
    },
    {
        id: 1008,
        name: 'Karthik karthik',
        emailId: 'Karthikkarthik@gmail.com',
        address: 'Saraswathipura, Nandini Layout, Bengaluru, Karnataka 560096',
        contact: '9999999999',
        country: 'Country1',
        role: 'RoleROle',
        assignedModules: 'Modules Accessing',
        reportingTo: 'Vijaykumar',
        status: 'Active',
        sloc: '1 sloc'
    },
    {
        id: 1009,
        name: 'Karthik karthik',
        emailId: 'Karthikkarthik@gmail.com',
        address: 'Saraswathipura, Nandini Layout, Bengaluru, Karnataka 560096',
        contact: '9999999999',
        country: 'Country1',
        role: 'RoleROle',
        assignedModules: 'Modules Accessing',
        reportingTo: 'Vijaykumar',
        status: 'In-Active',
        sloc: '4 sloc'
    },
    {
        id: 1010,
        name: 'Karthik karthik',
        emailId: 'Karthikkarthik@gmail.com',
        address: 'Saraswathipura, Nandini Layout, Bengaluru, Karnataka 560096',
        contact: '9999999999',
        country: 'Country1',
        role: 'RoleROle',
        assignedModules: 'Modules Accessing',
        reportingTo: 'Vijaykumar',
        status: 'Active',
        sloc: '2 sloc'
    },
    {
        id: 1011,
        name: 'Karthik karthik',
        emailId: 'Karthikkarthik@gmail.com',
        address: 'Saraswathipura, Nandini Layout, Bengaluru, Karnataka 560096',
        contact: '9999999999',
        country: 'Country1',
        role: 'RoleROle',
        assignedModules: 'Modules Accessing',
        reportingTo: 'Vijaykumar',
        status: 'In-Active',
        sloc: '5 sloc'
    },
];

const columns = [
    { id: 'id', label: 'ID', align: 'left', minWidth: '80px' },
    { id: 'name', label: 'NAME', align: 'left', minWidth: '150px' },
    { id: 'emailId', label: 'Email.ID', align: 'left', minWidth: '200px' },
    { id: 'address', label: 'ADDRESS', align: 'left', minWidth: '250px' },
    { id: 'contact', label: 'CONTACT', align: 'left', minWidth: '100px' },
    { id: 'country', label: 'COUNTRY', align: 'left', minWidth: '120px' },
    { id: 'role', label: 'ROLE', align: 'left', minWidth: '120px' },
    { id: 'assignedModules', label: 'ASSIGNED MODULES', align: 'left', minWidth: '200px' },
    { id: 'reportingTo', label: 'REPORTING TO', align: 'left', minWidth: '150px' },
    { id: 'status', label: 'STATUS', align: 'left', minWidth: '130px' },
    { id: 'setSloc', label: 'SET SLOC', align: 'left', minWidth: '120px' },
    { id: 'action', label: 'ACTION', align: 'left', minWidth: '80px', sortable: false },
];


const SortableHead = SortableContainer(({ children }) => {
    return (
        <TableHead>
            <TableRow>{children}</TableRow>
        </TableHead>
    );
});

const SortableCell = SortableElement(({ value }) => {
    return <TableCell>{value}</TableCell>;
});


const UserManagementTable = (props) => {

    const getStoredColumnsOrder = () => {
        const savedOrder = localStorage.getItem('columnsOrder');
        return savedOrder ? JSON.parse(savedOrder) : columns.map((col) => col.id);
    };

    const [order, setOrder] = useState('asc');
    const [orderBy, setOrderBy] = useState('id');
    const [anchorEl, setAnchorEl] = useState(null);
    const [currentSortColumn, setCurrentSortColumn] = useState(null);
    const [actionAnchorEl, setActionAnchorEl] = useState(false);
    const [selectedCustomer, setSelectedCustomer] = useState(null);
    const [clickedCell, setClickedCell] = useState({ rowId: null, columnId: null }); // Track clicked cell
    const [columnsOrder, setColumnsOrder] = useState(getStoredColumnsOrder());

    // Pagination state
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10); // Set initial rows per page
    const [paginatedData, setPaginatedData] = useState([]);

    const handleRequestSort = (event, property) => {
        setCurrentSortColumn(property);
        setAnchorEl(event.currentTarget);
    };

    const handleSortOrderChange = (order) => {
        setOrder(order);
        setOrderBy(currentSortColumn);
        setAnchorEl(null);
    };

    const handleActionClick = (event, customer) => {
        setSelectedCustomer(customer);
        setActionAnchorEl(event.currentTarget);
    };

    const handleActionClose = () => {
        setActionAnchorEl(false);
    };

    const handleMenuItemClick = (action) => {
        if (action === 'edit') {
            console.log('Edit Details for:', selectedCustomer);
            // Implement edit functionality here
        } else if (action === 'view') {
            console.log('View Details for:', selectedCustomer);
            // Implement view functionality here
        }
        handleActionClose();
    };

    const onReorderEnd = ({ oldIndex, newIndex }) => {
        const newOrder = Array.from(columnsOrder);
        const [moved] = newOrder.splice(oldIndex, 1);
        newOrder.splice(newIndex, 0, moved);
        setColumnsOrder(newOrder);
        localStorage.setItem('columnsOrder', JSON.stringify(newOrder)); // Store the new order
    };

    const sortedData = React.useMemo(() => {
        const stabilizedData = data.map((el, index) => [el, index]);
        stabilizedData.sort((a, b) => {
            const orderModifier = order === 'asc' ? 1 : -1;
            if (a[0][orderBy] < b[0][orderBy]) {
                return -1 * orderModifier;
            }
            if (a[0][orderBy] > b[0][orderBy]) {
                return 1 * orderModifier;
            }
            return a[1] - b[1];
        });
        return stabilizedData.map((el) => el[0]);
    }, [data, order, orderBy]);

    // Pagination logic
    useEffect(() => {
        const start = page * rowsPerPage; // Updated page logic to 0-based
        const end = start + rowsPerPage;
        setPaginatedData(sortedData.slice(start, end));
    }, [sortedData, page, rowsPerPage]);

    const handlePageChange = (newPage) => {
        setPage(newPage - 1); // Handle 1-based to 0-based page index for slicing
    };
    const handleRowsPerPageChange = (newRowsPerPage) => {
        setRowsPerPage(newRowsPerPage);
        setPage(0); // Reset to the first page when rows per page changes
    };

    return (
        <>
            <TableContainer component={Paper} sx={{ maxHeight: 440 }} className='table-container'>
                <Table>
                    {/* <TableHead>
                        <TableRow>
                            {columns.map((column) => (
                                <TableCell key={column.id} align={column.align} style={{ minWidth: column.minWidth }}>
                                    <div style={{ display: 'flex', alignItems: 'center' }} className='table-header-div'>
                                        {column.label}
                                        {column.sortable !== false && (
                                            <IconButton
                                                size="small"
                                                onClick={(event) => handleRequestSort(event, column.id)}
                                            >
                                                <img src={SortIcon} alt="Sort" />
                                            </IconButton>
                                        )}
                                    </div>
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead> */}
                    <SortableHead axis="x" onSortEnd={onReorderEnd}>
                        {columnsOrder.map((columnId, index) => {
                            const column = columns.find(col => col.id === columnId);
                            return (
                                <SortableCell key={column.id} index={index} value={
                                    <div style={{ display: 'flex', alignItems: 'center', cursor: 'pointer', minWidth: column.minWidth }}>
                                        {column.label}
                                        {column.sortable !== false && (
                                            <IconButton
                                                size="small"
                                                onClick={(event) => handleRequestSort(event, column.id)}
                                            >
                                                <img src={SortIcon} alt="Sort" />
                                            </IconButton>
                                        )}
                                    </div>
                                } />
                            );
                        })}
                    </SortableHead>
                    <TableBody>
                        {paginatedData.map((customer) => (
                            <TableRow key={customer.id} className='table-row-data-container'>
                                {columnsOrder.map(columnId => {
                                    const column = columns.find(col => col.id === columnId);
                                    return (
                                        <TableCell
                                            key={column.id}
                                            align={column.align}
                                            className={clickedCell.rowId === customer.id && clickedCell.columnId === column.id ? 'clicked-value' : ''}
                                            onMouseDown={() => setClickedCell({ rowId: customer.id, columnId: column.id })}
                                            onMouseUp={() => setClickedCell({ rowId: null, columnId: null })}
                                            onMouseLeave={() => setClickedCell({ rowId: null, columnId: null })}
                                        >
                                            {column.id === 'status' ? (
                                                <span
                                                    className={`status-badge ${customer.status === 'Active' ? 'complete-status' : 'cancel-status'}`}
                                                >
                                                    {customer.status}
                                                </span>
                                            ) : column.id === 'setSloc' ? (
                                                <span className='sloc-div' onClick={props.handleOpenSloc}>
                                                    <img src={addIcon} alt="addIcon" />&nbsp;&nbsp;
                                                    {customer.sloc}
                                                </span>
                                            ) : column.id === 'action' ? (
                                                <SbActionDropdownButton
                                                    openAddModal={props.handleOpenModal}
                                                    addBulkModel={props.handleOpenViewModal}
                                                />
                                            ) : (
                                                // customer[column.id]
                                                <span
                                                    onClick={() => {
                                                        setClickedCell({ rowId: customer.id, columnId: column.id }); // Track clicked cell
                                                        props.handleOpenValueFilterModal(); // Open modal
                                                    }}
                                                    style={{ cursor: "pointer" }}
                                                >
                                                    {customer[column.id]}
                                                </span>
                                            )}
                                        </TableCell>
                                    );
                                })}
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
                <Menu
                    anchorEl={anchorEl}
                    open={Boolean(anchorEl)}
                    onClose={() => setAnchorEl(null)}
                    className='header-menu-items'
                >
                    <MenuItem onClick={() => handleSortOrderChange('asc')} className='header-menu-options'>
                        <img src={ascToDesc} alt="" />&nbsp;&nbsp;
                        Ascending to Descending
                    </MenuItem>
                    <MenuItem onClick={() => handleSortOrderChange('desc')} className='header-menu-options'>
                        <img src={descToAsc} alt="" />&nbsp;&nbsp;
                        Descending to Ascending
                    </MenuItem>
                </Menu>
            </TableContainer>
            {/* Use PaginationComponent */}
            <PaginationComponent
                totalItems={data.length}
                itemsPerPage={rowsPerPage}
                onPageChange={handlePageChange}
                onRowsPerPageChange={handleRowsPerPageChange}
            />
        </>
    );
};

export default UserManagementTable;