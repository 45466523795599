import React, { useEffect, useState } from 'react';
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    IconButton,
    Menu,
    MenuItem
} from '@mui/material';
import SortIcon from '../../../assets/images/Dashboard/chevron-down.svg';
import ascToDesc from '../../../assets/images/CustomerManagement/ascToDesc.svg';
import descToAsc from '../../../assets/images/CustomerManagement/descToAsc.svg';
import SbActionDropdownButton from '../../Buttons/SbActionDropdownButton';
import StatusBadgeWithDropdown from '../../Buttons/StatusBadgeWithDropdown';
import { Col, Dropdown, Row } from 'react-bootstrap';
import { SbAddBtn, SbFilterBtn } from '../../Buttons/Buttons';
import Select from 'react-select';
import columnFilter from '../../../assets/images/CustomerManagement/columnFilter.svg'
import valueFilter from '../../../assets/images/CustomerManagement/valueFilter.svg'
import AddLeavePolicy from './AddLeavePolicy';
import PaginationComponent from '../../PaginationComponent';
import LPColumnFilterModal from './LPColumnFilterModal';
import LPValueFilterModal from './LPValueFilterModal';

const data = [
    {
        leaveType: 'Casual Leave',
        shortName: 'CL',
        maxNoofDays: '03',
        country: 'India',
        description: 'Casual Leave',
        status: 'Active',
    },
    {
        leaveType: 'Earned Leave',
        shortName: 'EARN',
        maxNoofDays: '03',
        country: 'US',
        description: 'Earned Leave',
        status: 'Inactive',
    },
    {
        leaveType: 'Loss of Pay',
        shortName: 'LOP',
        maxNoofDays: '03',
        country: 'India',
        description: 'This leave allow user to avail leave with deduction of pay for requested days',
        status: 'Active',
    },
    {
        leaveType: 'Restricted Holiday',
        shortName: 'RH',
        maxNoofDays: '03',
        country: 'US',
        description: 'You can use this leave on restricted holidays',
        status: 'Active',
    },
];

const columns = [
    { id: 'leaveType', label: 'LEAVE TYPE', align: 'left', minWidth: '150px' },
    { id: 'shortName', label: 'SHORT NAME', align: 'left', minWidth: '150px' },
    { id: 'maxNoofDays', label: 'MAX NO.OF.DAYS', align: 'left', minWidth: '150px' },
    { id: 'country', label: 'COUNTRY', align: 'left', minWidth: '100px' },
    { id: 'description', label: 'DESCRIPTION', align: 'left', minWidth: '150px' },
    { id: 'status', label: 'STATUS', align: 'left', minWidth: '80px' },
    { id: 'action', label: 'ACTION', align: 'left', minWidth: '80px', sortable: false },
];

const LeavePolicyTable = (props) => {
    const [order, setOrder] = useState('asc');
    const [orderBy, setOrderBy] = useState('id');
    const [anchorEl, setAnchorEl] = useState(null);
    const [currentSortColumn, setCurrentSortColumn] = useState(null);
    const [actionAnchorEl, setActionAnchorEl] = useState(false);
    const [selectedCustomer, setSelectedCustomer] = useState(null);
    const [showCustomerViewDetails, setShowCustomerViewDetails] = useState(false);

    // Pagination state
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10); // Set initial rows per page
    const [paginatedData, setPaginatedData] = useState([]);

    const handleRequestSort = (event, property) => {
        setCurrentSortColumn(property);
        setAnchorEl(event.currentTarget);
    };

    const handleSortOrderChange = (order) => {
        setOrder(order);
        setOrderBy(currentSortColumn);
        setAnchorEl(null);
    };

    const handleActionClick = (event, customer) => {
        setSelectedCustomer(customer);
        setActionAnchorEl(event.currentTarget);
    };

    const handleActionClose = () => {
        setActionAnchorEl(false);
    };

    const sortedData = React.useMemo(() => {
        const stabilizedData = data.map((el, index) => [el, index]);
        stabilizedData.sort((a, b) => {
            const orderModifier = order === 'asc' ? 1 : -1;
            if (a[0][orderBy] < b[0][orderBy]) {
                return -1 * orderModifier;
            }
            if (a[0][orderBy] > b[0][orderBy]) {
                return 1 * orderModifier;
            }
            return a[1] - b[1];
        });
        return stabilizedData.map((el) => el[0]);
    }, [data, order, orderBy]);

    const [fromDate, setFromDate] = useState(null);
    const [toDate, setToDate] = useState(null);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [showFilterMenu, setShowFilterMenu] = useState(false);
    const [showColumnFilterModal, setShowColumnFilterModal] = useState(false);
    const [showValueFilterModal, setShowValueFilterModal] = useState(false);

    const toggleFilterMenu = () => {
        setShowFilterMenu(!showFilterMenu);
    };

    const handleFromDateChange = (date) => {
        setFromDate(date);
    };

    const handleToDateChange = (date) => {
        setToDate(date);
    };
    const handleOpenModal = () => {
        setIsModalOpen(true);
    };

    const handleCloseModal = () => {
        setIsModalOpen(false);
    };
    const handleMenuItemClick = (action) => {
        if (action === 'edit') {
            setIsModalOpen(true);
        } else if (action === 'view') {
            console.log('View Details for:', selectedCustomer);
            // Implement view functionality here
        }
        handleActionClose();
    };
    const handleOpenColumnFilterModal = () => {
        setShowFilterMenu(false);
        setShowColumnFilterModal(true);
    };

    const handleCloseColumnFilterModal = () => {
        setShowColumnFilterModal(false);
    };
    const handleOpenValueFilterModal = () => {
        setShowFilterMenu(false);
        setShowValueFilterModal(true);
    };

    const handleCloseValueFilterModal = () => {
        setShowValueFilterModal(false);
    };

    // Pagination logic
    useEffect(() => {
        const start = page * rowsPerPage; // Updated page logic to 0-based
        const end = start + rowsPerPage;
        setPaginatedData(sortedData.slice(start, end));
    }, [sortedData, page, rowsPerPage]);

    const handlePageChange = (newPage) => {
        setPage(newPage - 1); // Handle 1-based to 0-based page index for slicing
    };
    const handleRowsPerPageChange = (newRowsPerPage) => {
        setRowsPerPage(newRowsPerPage);
        setPage(0); // Reset to the first page when rows per page changes
    };

    return (
        <>
            <Row className='customermanagement-btns-container'>
                <Col md={3} className='customermanagement-backbtn-div'>
                    <span>Leave Policy</span>
                </Col>
                <Col md={9} className='customermanagement-search-filter'>
                    <div className='customermanagement-header-btns filter-btn'>
                        <SbFilterBtn btnName="Filters" onClickEffect={toggleFilterMenu} />
                        {showFilterMenu && (
                            <Dropdown.Menu show className='filter-dropdown-options'>
                                <Dropdown.Item onClick={handleOpenColumnFilterModal}>
                                    <img src={columnFilter} alt="Column Filter" />&nbsp;&nbsp;
                                    Column Filter
                                </Dropdown.Item>
                                <Dropdown.Item onClick={handleOpenValueFilterModal}>
                                    <img src={valueFilter} alt="Value Filter" />&nbsp;&nbsp;
                                    Value Filter
                                </Dropdown.Item>
                            </Dropdown.Menu>
                        )}
                    </div>
                    <Col md={3} className='filters-col'>
                        <Select
                            className="react-select-container"
                            classNamePrefix="react-select"
                            placeholder="Search Leave Type"
                            // options={statusOptions}
                            style={{ width: "100%" }}
                        />
                    </Col>
                    <Col md={3} className='filters-col'>
                        <Select
                            className="react-select-container"
                            classNamePrefix="react-select"
                            placeholder="Search Status"
                            // options={statusOptions}
                            style={{ width: "100%" }}
                        />
                    </Col>
                    <SbAddBtn btnName="Add" onClickEffect={handleOpenModal} />
                </Col>
            </Row>
            <div className='customermanagement-table-div'>
                <TableContainer component={Paper} sx={{ maxHeight: 440 }} className='table-container'>
                    <Table>
                        <TableHead>
                            <TableRow>
                                {columns.map((column) => (
                                    <TableCell key={column.id} align={column.align} style={{ minWidth: column.minWidth }}>
                                        <div style={{ display: 'flex', alignItems: 'center' }} className='table-header-div'>
                                            {column.label}
                                            {column.sortable !== false && (
                                                <IconButton
                                                    size="small"
                                                    onClick={(event) => handleRequestSort(event, column.id)}
                                                >
                                                    <img src={SortIcon} alt="Sort" />
                                                </IconButton>
                                            )}
                                        </div>
                                    </TableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {paginatedData.map((customer) => (
                                <TableRow key={customer.id}>
                                    {columns.map((column) => (
                                        <TableCell key={column.id} align={column.align}>
                                            {column.id === 'status' ? (
                                                <StatusBadgeWithDropdown
                                                    customer={customer}
                                                    activeText="Active"
                                                    inactiveText="Inactive"
                                                />
                                            ) : column.id === 'action' ? (
                                                <SbActionDropdownButton
                                                    options={[{ type: 'Edit' }]}
                                                    openAddModal={handleOpenModal}
                                                />
                                            ) : (
                                                customer[column.id]
                                            )}
                                        </TableCell>
                                    ))}
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                    <Menu
                        anchorEl={anchorEl}
                        open={Boolean(anchorEl)}
                        onClose={() => setAnchorEl(null)}
                    >
                        <MenuItem onClick={() => handleSortOrderChange('asc')}>
                            <img src={ascToDesc} alt="" />&nbsp;&nbsp;
                            Ascending to Descending
                        </MenuItem>
                        <MenuItem onClick={() => handleSortOrderChange('desc')}>
                            <img src={descToAsc} alt="" />&nbsp;&nbsp;
                            Descending to Ascending
                        </MenuItem>
                    </Menu>
                </TableContainer>
                {/* Use PaginationComponent */}
                <PaginationComponent
                    totalItems={data.length}
                    itemsPerPage={rowsPerPage}
                    onPageChange={handlePageChange}
                    onRowsPerPageChange={handleRowsPerPageChange}
                />
                {isModalOpen && <AddLeavePolicy open={isModalOpen} handleCloseModal={handleCloseModal} />}
                {
                    showColumnFilterModal && (
                        <LPColumnFilterModal
                            showColumnFilterModal={showColumnFilterModal}
                            handleCloseColumnFilterModal={handleCloseColumnFilterModal}
                        />
                    )
                }
                {
                    showValueFilterModal && (
                        <LPValueFilterModal
                            showValueFilterModal={showValueFilterModal}
                            handleCloseValueFilterModal={handleCloseValueFilterModal}
                        />
                    )
                }
            </div>
        </>
    );
};

export default LeavePolicyTable;


