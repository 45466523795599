import React, { useState } from "react";
import { Col, Form, Modal, Row } from "react-bootstrap";
import SbCancelBtn, { SbAddBtn, SBSaveUpdateBtn } from "../../Buttons/Buttons";
import Select from 'react-select';
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, IconButton } from "@mui/material";
// import DeleteIcon from '@mui/icons-material/Delete';
import deleteImg from '../../../assets/images/Settings/deleteImage.svg'

const enablingOptions = [
    { value: "Enable", label: "Enable" },
    { value: "Disable", label: "Disable" }
];

const initialCriteria = [
    { fieldName: "End Date", operator: "greater Than", value: "25-07-2024" },
    { fieldName: "End Date", operator: "Less Than", value: "31-08-2024" }
];

const AddWqRules = (props) => {
    const [criteriaList, setCriteriaList] = useState(initialCriteria);

    const handleRemoveCriterion = (index) => {
        const updatedCriteria = criteriaList.filter((_, i) => i !== index);
        setCriteriaList(updatedCriteria);
    };

    return (
        <>
            <Modal show={props.open} onHide={props.handleCloseModal} className='add-customer-modal'>
                <Modal.Header closeButton>
                    <Modal.Title>Add Work Queue Rule</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row className='customer-form-fields  customer-branch-plant-form-fields'>
                        <Col>
                            <Row>
                                <Col md={12}>
                                    <div>
                                        <label>Module Name</label>
                                        <Select
                                            className="react-select-container"
                                            classNamePrefix="react-select"
                                            // options={enablingOptions}
                                            placeholder="Select"
                                        />
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={12}>
                                    <div>
                                        <label>Rule Name</label>
                                        <Form.Control type="text" />
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={12}>
                                    <div>
                                        <label>Description</label>
                                        <Form.Control type="text" />
                                    </div>
                                </Col>
                            </Row>
                            <div className="wq-modal-table-container">
                                <Row>
                                    <Col md={6}>
                                        <div>
                                            <label>Field Name</label>
                                            <Select
                                                className="react-select-container"
                                                classNamePrefix="react-select"
                                                // options={enablingOptions}
                                                placeholder="Select"
                                            />
                                        </div>
                                    </Col>
                                    <Col md={6}>
                                        <div>
                                            <label>Operator</label>
                                            <Select
                                                className="react-select-container"
                                                classNamePrefix="react-select"
                                                // options={enablingOptions}
                                                placeholder="Select"
                                            />
                                        </div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md={6}>
                                        <div>
                                            <label>Value</label>
                                            <Select
                                                className="react-select-container"
                                                classNamePrefix="react-select"
                                                // options={enablingOptions}
                                                placeholder="Select"
                                            />
                                        </div>
                                    </Col>
                                    <Col md={6} className='d-flex align-items-end'>
                                        <SbAddBtn btnName="Add To Criteria" />
                                    </Col>
                                </Row>
                                <Row className='add-wqRules-table-div'>
                                    <TableContainer component={Paper} sx={{ maxHeight: 440 }} className='table-container add-wqRules-table-container' style={{ boxShadow: "none" }}>
                                        <Table stickyHeader>
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell className='table-header-div'>Field Name</TableCell>
                                                    <TableCell align="center" className='table-header-div'>Operator</TableCell>
                                                    <TableCell align="center" className='table-header-div'>Value</TableCell>
                                                    <TableCell align="center" className='table-header-div'>Action</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {criteriaList.map((criterion, index) => (
                                                    <TableRow key={index}>
                                                        <TableCell>{criterion.fieldName}</TableCell>
                                                        <TableCell align="center">{criterion.operator}</TableCell>
                                                        <TableCell align="center">{criterion.value}</TableCell>
                                                        <TableCell align="center">
                                                            {/* <IconButton aria-label="delete" onClick={() => handleRemoveCriterion(index)}>
                                                                <DeleteIcon />
                                                            </IconButton> */}
                                                            <img src={deleteImg} alt="" onClick={() => handleRemoveCriterion(index)}/>
                                                        </TableCell>
                                                    </TableRow>
                                                ))}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </Row>
                            </div>
                            <Row>
                                <Col md={4}>
                                    <div>
                                        <label>Status</label>
                                        <Select
                                            className="react-select-container"
                                            classNamePrefix="react-select"
                                            options={enablingOptions}
                                            placeholder="Select"
                                        />
                                    </div>
                                </Col>
                            </Row>
                        </Col>
                    </Row >
                </Modal.Body >
                <Modal.Footer>
                    <SbCancelBtn btnName="Cancel" onClickEffect={props.handleCloseModal} />
                    <SBSaveUpdateBtn btnName="Submit" onClickEffect={props.handleNextStep} />
                </Modal.Footer>
            </Modal >
        </>
    );
}

export default AddWqRules;
