import React, { useState } from 'react';
import { Col, Row, Container, ListGroup, Tab, Nav, Accordion } from "react-bootstrap";
import Main from "../Main";
import customerImg from '../../assets/images/CustomerManagement/customer-img.svg';
import Download from '../MainSection/Download/Download';

const CustomerViewDetails = ({ handleAnalyticsClick }) => {
    // State to manage which accordion item is open
    const [activeKey, setActiveKey] = useState(null);

    // Example data structure for dynamic accordions
    const businessUnits = [
        { id: '0', name: 'Business Unit Name1', details: { code: 'code-1', name: 'Lobotus unit 1', type: 'Business Unit', reportingTo: 'Lobotus Company' } },
        { id: '1', name: 'Business Unit Name2', details: { code: 'code-2', name: 'Lobotus unit 2', type: 'Business Unit', reportingTo: 'Lobotus Company' } },
        { id: '2', name: 'Business Unit Name3', details: { code: 'code-3', name: 'Lobotus unit 3', type: 'Business Unit', reportingTo: 'Lobotus Company' } }
    ];

    const branchPlants = [
        { id: '0', name: 'Branch / Plant name1', details: { code: 'code-1', name: 'Lobotus unit 1 west', email: 'KarthikKarthik@gmail.com', contact: '+91 9999999999', reportingType: 'Business unit', reportingTo: 'Lobotus Unit', licenses: 5, relationship: 'Branch', address: 'race course road bangalore', zipcode: '622515' } },
        { id: '1', name: 'Branch / Plant name2', details: { code: 'code-2', name: 'Lobotus unit 2 west', email: 'KarthikKarthik@gmail.com', contact: '+91 9999999999', reportingType: 'Business unit', reportingTo: 'Lobotus Unit', licenses: 5, relationship: 'Branch', address: 'race course road bangalore', zipcode: '622515' } },
        { id: '2', name: 'Branch / Plant name3', details: { code: 'code-3', name: 'Lobotus unit 3 west', email: 'KarthikKarthik@gmail.com', contact: '+91 9999999999', reportingType: 'Business unit', reportingTo: 'Lobotus Unit', licenses: 5, relationship: 'Branch', address: 'race course road bangalore', zipcode: '622515' } }
    ];

    const storageLocations = [
        { id: '0', name: 'Storage Location Name1', details: { code: 'code-1', name: 'Lobotus unit 1 west storage', type: 'Type1', reportingType: 'Branch / Plant', reportingTo: 'Lobotus unit 1 west' } },
        { id: '1', name: 'Storage Location Name2', details: { code: 'code-2', name: 'Lobotus unit 1 west storage', type: 'Type1', reportingType: 'Branch / Plant', reportingTo: 'Lobotus unit 1 west' } },
        { id: '2', name: 'Storage Location Name3', details: { code: 'code-3', name: 'Lobotus unit 1 west storage', type: 'Type1', reportingType: 'Branch / Plant', reportingTo: 'Lobotus unit 1 west' } }
    ];

    return (
        <div className="customer-management-view-details-container">
            <Main>
                {/* <Container> */}
                <div className='components-download-container'>
                    <Download
                        moduleName="Back"
                        showBackButton={true}
                        showDashboardLibraryButton={false}
                        showSaveCustomViewButton={false}
                        showAnalyticsButton={true}
                        showDownloadDropdown={true}
                        onClick={handleAnalyticsClick}
                    />
                </div>
                <div className='customermanagement-content-container'>
                    <div>
                        <Row className="customermanagement-view-details-btns-container">
                            {/* Left Side - Head-Quarters Details */}
                            <Col md={4} className='customermanagement-view-details-first-col'>
                                <div className="customermanagement-view-details-header">
                                    <p>Head Quarters Details</p>
                                    <img src={customerImg} alt="" />
                                </div>
                                <ListGroup variant="flush" className='head-quarters-list-items'>
                                    <ListGroup.Item className='mt-3'><p>Company Code</p> <p>: </p><p>143</p></ListGroup.Item>
                                    <ListGroup.Item><p>Company Name</p> <p>: </p><p>Lobotus Company</p></ListGroup.Item>
                                    <ListGroup.Item><p>Contact Name</p> <p>:</p><p>karthik</p></ListGroup.Item>
                                    <ListGroup.Item><p>Email ID</p><p>:</p> <p>KarthikKarthik@gmail.com</p></ListGroup.Item>
                                    <ListGroup.Item><p>No. of Licenses</p> <p>:</p><p>09</p></ListGroup.Item>
                                    <ListGroup.Item><p>Contact Number</p> <p>:</p><p>9999999999</p></ListGroup.Item>
                                    <ListGroup.Item><p>Classification</p> <p>:</p><p>Internal</p></ListGroup.Item>
                                    <ListGroup.Item><p>Address</p> <p>:</p><p>AddressAddressAddressAddress</p></ListGroup.Item>
                                    <ListGroup.Item><p>Zip Code</p> <p>:</p><p>625215</p></ListGroup.Item>
                                    <ListGroup.Item><p>Relationship Type</p> <p>:</p><p>Headquarter</p></ListGroup.Item>
                                    <ListGroup.Item><p>Start Date</p> <p>:</p><p>mm/dd/yyyy</p></ListGroup.Item>
                                    <ListGroup.Item><p>End Date</p> <p>:</p><p>mm/dd/yyyy</p></ListGroup.Item>
                                    <ListGroup.Item><p>Email ID</p> <p>:</p><p>BID123456</p></ListGroup.Item>
                                    <ListGroup.Item><p>Assigned Modules</p><p>:</p><p>Modules Accessible1, Modules Accessible2, Modules Accessible3, Modules Accessible4, Modules Accessible5, Modules Accessible6, Modules Accessible7, Modules Accessible8</p>
                                    </ListGroup.Item>
                                    <ListGroup.Item><p>Website</p> <p>:</p><p>LinkdLinkdLinkd</p></ListGroup.Item>
                                    <ListGroup.Item><p>Status</p><p>:</p> <p className="text-success-color">Active</p></ListGroup.Item>
                                </ListGroup>
                            </Col>

                            {/* Right Side - Dynamic Content */}
                            <Col md={8}>
                                <div className="customermanagement-view-details-second-header">
                                    <p>Branch Variant Details</p>
                                </div>
                                <Tab.Container defaultActiveKey="businessUnit">
                                    <Nav variant="tabs">
                                        <Nav.Item>
                                            <Nav.Link eventKey="businessUnit">Business Unit ({businessUnits.length} units)</Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item>
                                            <Nav.Link eventKey="branchPlant">Branch / Plant ({branchPlants.length} branches)</Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item>
                                            <Nav.Link eventKey="storageLocation">Storage Location ({storageLocations.length} Slots)</Nav.Link>
                                        </Nav.Item>
                                    </Nav>
                                    <Tab.Content className='customermanagement-tab-accordion'>
                                        <Tab.Pane eventKey="businessUnit">
                                            <Accordion activeKey={activeKey} onSelect={(key) => setActiveKey(key)}>
                                                {businessUnits.map(unit => (
                                                    <Accordion.Item eventKey={unit.id} key={unit.id}>
                                                        <Accordion.Header className='customermanagement-view-accordian-header'>{unit.name}</Accordion.Header>
                                                        <Accordion.Body>
                                                            <Row>
                                                                <Col md={3} className='mb-3'>
                                                                    <p>Business Unit Code</p>
                                                                    <span>{unit.details.code}</span>
                                                                </Col>
                                                                <Col md={3} className='mb-3'>
                                                                    <p>Business Unit Name</p>
                                                                    <span>{unit.details.name}</span>
                                                                </Col>
                                                                <Col md={3} className='mb-3'>
                                                                    <p>Relationship Type</p>
                                                                    <span>{unit.details.type}</span>
                                                                </Col>
                                                                <Col md={3} className='mb-3'>
                                                                    <p>Reporting To</p>
                                                                    <span>{unit.details.reportingTo}</span>
                                                                </Col>
                                                            </Row>
                                                        </Accordion.Body>
                                                    </Accordion.Item>
                                                ))}
                                            </Accordion>
                                        </Tab.Pane>
                                        <Tab.Pane eventKey="branchPlant">
                                            <Accordion activeKey={activeKey} onSelect={(key) => setActiveKey(key)}>
                                                {branchPlants.map(branch => (
                                                    <Accordion.Item eventKey={branch.id} key={branch.id}>
                                                        <Accordion.Header className='customermanagement-view-accordian-header'>{branch.name}</Accordion.Header>
                                                        <Accordion.Body>
                                                            <Row>
                                                                <Col md={4} className='mb-3'>
                                                                    <p>Branch/Plant Code</p>
                                                                    <span>{branch.details.code}</span>
                                                                </Col>
                                                                <Col md={4} className='mb-3'>
                                                                    <p>Branch/Plant Name</p>
                                                                    <span>{branch.details.name}</span>
                                                                </Col>
                                                                <Col md={4} className='mb-3'>
                                                                    <p>Email</p>
                                                                    <span>{branch.details.email}</span>
                                                                </Col>

                                                            </Row>
                                                            <Row>
                                                                <Col md={4} className='mb-3'>
                                                                    <p>Contact number</p>
                                                                    <span>{branch.details.contact}</span>
                                                                </Col>
                                                                <Col md={4} className='mb-3'>
                                                                    <p>Reporting Type</p>
                                                                    <span>{branch.details.reportingType}</span>
                                                                </Col>
                                                                <Col md={4} className='mb-3'>
                                                                    <p>Reporting To</p>
                                                                    <span>{branch.details.reportingTo}</span>
                                                                </Col>

                                                            </Row>
                                                            <Row>
                                                                <Col md={4} className='mb-3'>
                                                                    <p>Number Of Licenses</p>
                                                                    <span>{branch.details.licenses}</span>
                                                                </Col>
                                                                <Col md={4} className='mb-3'>
                                                                    <p>Relationship Type</p>
                                                                    <span>{branch.details.relationship}</span>
                                                                </Col>
                                                                <Col md={4} className='mb-3'>
                                                                    <p>Address</p>
                                                                    <span>{branch.details.address}</span>
                                                                </Col>
                                                            </Row>
                                                            <Row>
                                                                <Col md={4} className='mb-3'>
                                                                    <p>Zipcode</p>
                                                                    <span>{branch.details.zipcode}</span>
                                                                </Col>
                                                            </Row>
                                                        </Accordion.Body>
                                                    </Accordion.Item>
                                                ))}
                                            </Accordion>
                                        </Tab.Pane>
                                        <Tab.Pane eventKey="storageLocation">
                                            <Accordion activeKey={activeKey} onSelect={(key) => setActiveKey(key)}>
                                                {storageLocations.map(location => (
                                                    <Accordion.Item eventKey={location.id} key={location.id}>
                                                        <Accordion.Header className='customermanagement-view-accordian-header'>{location.name}</Accordion.Header>
                                                        <Accordion.Body>
                                                            <Row>
                                                                <Col md={4} className='mb-3'>
                                                                    <p>Storage Location Code</p>
                                                                    <span>{location.details.code}</span>
                                                                </Col>
                                                                <Col md={4} className='mb-3'>
                                                                    <p>Storage Location Name</p>
                                                                    <span>{location.details.name}</span>
                                                                </Col>
                                                                <Col md={4} className='mb-3'>
                                                                    <p>Relationship Type</p>
                                                                    <span>{location.details.type}</span>
                                                                </Col>
                                                            </Row>
                                                            <Row>
                                                                <Col md={4} className='mb-3'>
                                                                    <p>Reporting Type</p>
                                                                    <span>{location.details.reportingType}</span>
                                                                </Col>
                                                                <Col md={4} className='mb-3'>
                                                                    <p>Reporting To</p>
                                                                    <span>{location.details.reportingTo}</span>
                                                                </Col>
                                                            </Row>
                                                        </Accordion.Body>
                                                    </Accordion.Item>
                                                ))}
                                            </Accordion>
                                        </Tab.Pane>
                                    </Tab.Content>
                                </Tab.Container>
                            </Col>
                        </Row>
                    </div>
                </div>
                {/* </Container> */}
            </Main >
        </div >
    );
}

export default CustomerViewDetails;

