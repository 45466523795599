import React, { useEffect, useState } from 'react';
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    IconButton,
    Menu,
    MenuItem
} from '@mui/material';
import SortIcon from '../../../assets/images/Dashboard/chevron-down.svg';
import ascToDesc from '../../../assets/images/CustomerManagement/ascToDesc.svg';
import descToAsc from '../../../assets/images/CustomerManagement/descToAsc.svg';
import SbActionDropdownButton from '../../Buttons/SbActionDropdownButton';
import StatusBadgeWithDropdown from '../../Buttons/StatusBadgeWithDropdown';
import PaginationComponent from '../../PaginationComponent';

const data = [
    {
        department: 'admin',
        status: 'Enable',
    },
    {
        department: 'department1',
        status: 'Disable',
    },
    {
        department: 'department2',
        status: 'Enable',
    },
    {
        department: 'department3',
        status: 'Disable',
    },
];

const columns = [
    { id: 'department', label: 'DEPARTMENT', align: 'left', minWidth: '650px' },
    { id: 'status', label: 'STATUS', align: 'left', minWidth: '80px' },
    { id: 'action', label: 'ACTION', align: 'left', minWidth: '80px', sortable: false },
];

const DepartmentTable = (props) => {
    const [order, setOrder] = useState('asc');
    const [orderBy, setOrderBy] = useState('id');
    const [anchorEl, setAnchorEl] = useState(null);
    const [currentSortColumn, setCurrentSortColumn] = useState(null);
    const [actionAnchorEl, setActionAnchorEl] = useState(false);
    const [selectedCustomer, setSelectedCustomer] = useState(null);

    // Pagination state
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10); // Set initial rows per page
    const [paginatedData, setPaginatedData] = useState([]);

    const handleRequestSort = (event, property) => {
        setCurrentSortColumn(property);
        setAnchorEl(event.currentTarget);
    };

    const handleSortOrderChange = (order) => {
        setOrder(order);
        setOrderBy(currentSortColumn);
        setAnchorEl(null);
    };

    const handleActionClick = (event, customer) => {
        setSelectedCustomer(customer);
        setActionAnchorEl(event.currentTarget);
    };

    const handleActionClose = () => {
        setActionAnchorEl(false);
    };

    const handleMenuItemClick = (action) => {
        if (action === 'edit') {
            console.log('Edit Details for:', selectedCustomer);
            // Implement edit functionality here
        } else if (action === 'view') {
            console.log('View Details for:', selectedCustomer);
            // Implement view functionality here
        }
        handleActionClose();
    };

    const sortedData = React.useMemo(() => {
        const stabilizedData = data.map((el, index) => [el, index]);
        stabilizedData.sort((a, b) => {
            const orderModifier = order === 'asc' ? 1 : -1;
            if (a[0][orderBy] < b[0][orderBy]) {
                return -1 * orderModifier;
            }
            if (a[0][orderBy] > b[0][orderBy]) {
                return 1 * orderModifier;
            }
            return a[1] - b[1];
        });
        return stabilizedData.map((el) => el[0]);
    }, [data, order, orderBy]);

    // Pagination logic
    useEffect(() => {
        const start = page * rowsPerPage; // Updated page logic to 0-based
        const end = start + rowsPerPage;
        setPaginatedData(sortedData.slice(start, end));
    }, [sortedData, page, rowsPerPage]);

    const handlePageChange = (newPage) => {
        setPage(newPage - 1); // Handle 1-based to 0-based page index for slicing
    };
    const handleRowsPerPageChange = (newRowsPerPage) => {
        setRowsPerPage(newRowsPerPage);
        setPage(0); // Reset to the first page when rows per page changes
    };

    return (
        <>
            <TableContainer component={Paper} sx={{ maxHeight: 440 }} className='table-container'>
                <Table>
                    <TableHead>
                        <TableRow>
                            {columns.map((column) => (
                                <TableCell key={column.id} align={column.align} style={{ minWidth: column.minWidth }}>
                                    <div style={{ display: 'flex', alignItems: 'center' }} className='table-header-div'>
                                        {column.label}
                                        {column.sortable !== false && (
                                            <IconButton
                                                size="small"
                                                onClick={(event) => handleRequestSort(event, column.id)}
                                            >
                                                <img src={SortIcon} alt="Sort" />
                                            </IconButton>
                                        )}
                                    </div>
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {paginatedData.map((customer) => (
                            <TableRow key={customer.id}>
                                {columns.map((column) => (
                                    <TableCell key={column.id} align={column.align}>
                                        {column.id === 'status' ? (
                                            // <span
                                            // className={`status-badge-for-enabling ${customer.status === 'Enable' ? 'complete-status' : 'cancel-status'}`}
                                            // >
                                            //     {customer.status}&nbsp;&nbsp;&nbsp;&nbsp;
                                            //     <span className="dropdown-arrow">
                                            //         <img src={SortIcon} alt="dropdown icon" />
                                            //     </span>
                                            // </span>
                                            <StatusBadgeWithDropdown customer={customer} activeText="Enable"
                                                inactiveText="Disable" />
                                        ) : column.id === 'action' ? (
                                            <SbActionDropdownButton
                                                options={[{ type: 'Edit' }]}
                                                openAddModal={props.handleOpenModal}
                                            />
                                        ) : (
                                            customer[column.id]
                                        )}
                                    </TableCell>

                                ))}
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
                <Menu
                    anchorEl={anchorEl}
                    open={Boolean(anchorEl)}
                    onClose={() => setAnchorEl(null)}
                >
                    <MenuItem onClick={() => handleSortOrderChange('asc')}>
                        <img src={ascToDesc} alt="" />&nbsp;&nbsp;
                        Ascending to Descending
                    </MenuItem>
                    <MenuItem onClick={() => handleSortOrderChange('desc')}>
                        <img src={descToAsc} alt="" />&nbsp;&nbsp;
                        Descending to Ascending
                    </MenuItem>
                </Menu>
            </TableContainer>
            {/* Use PaginationComponent */}
            <PaginationComponent
                totalItems={data.length}
                itemsPerPage={rowsPerPage}
                onPageChange={handlePageChange}
                onRowsPerPageChange={handleRowsPerPageChange}
            />
        </>
    );
};

export default DepartmentTable;
