import React, { useState } from "react";
import { Col, Form, Modal, Row } from "react-bootstrap";
import SbCancelBtn, { SBSaveUpdateBtn } from "../../Buttons/Buttons";
import Select from 'react-select';
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Checkbox, styled } from "@mui/material";

const enablingOptions = [
    { value: "Enable", label: "Enable" },
    { value: "Disable", label: "Disable" }
]
const CustomCheckbox = styled(Checkbox)({
    color: '#00c853', // Green checkmark color
    '&.Mui-checked': {
        color: '#00c853', // Green checkmark when checked
    },
    '& .MuiSvgIcon-root': {
        fontSize: 20, // Size of the checkbox
        backgroundColor: 'white', // White background
        borderRadius: 4, // Optional: to make the checkbox corners slightly rounded
    },
});
const AddRoles = (props) => {

    // Initialize state for modules
    const [modules, setModules] = useState([
        { name: "Leave Management", view: true, create: false, update: true, financialApproval: true, transactionalApproval: true },
        { name: "Complaints and Escalation", view: true, create: true, update: true, financialApproval: true, transactionalApproval: true },
        { name: "AMC or SLA Module", view: true, create: true, update: true, financialApproval: true, transactionalApproval: true },
        { name: "Ticketing Interface", view: true, create: true, update: true, financialApproval: true, transactionalApproval: true },
        { name: "Planning & Forecasting", view: true, create: true, update: true, financialApproval: true, transactionalApproval: true },
        { name: "Inventory", view: true, create: true, update: true, financialApproval: false, transactionalApproval: true },
        { name: "Warehousing", view: true, create: true, update: true, financialApproval: false, transactionalApproval: true },
        { name: "Category Management", view: true, create: false, update: true, financialApproval: true, transactionalApproval: true },
        { name: "Investment Recovery", view: true, create: true, update: true, financialApproval: true, transactionalApproval: true },
        { name: "Logistics & Delivery", view: true, create: true, update: true, financialApproval: false, transactionalApproval: true },
    ]);

    // Handle checkbox change
    const handleCheckboxChange = (index, permission) => {
        const updatedModules = [...modules];
        updatedModules[index][permission] = !updatedModules[index][permission];
        setModules(updatedModules);
    };

    return (
        <>
            <Modal show={props.open} onHide={props.handleCloseModal} className='add-customer-modal add-role-modal'>
                <Modal.Header closeButton>
                    <Modal.Title>Add Roles</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row className='customer-form-fields'>
                        <Col>
                            <Row>
                                <Col md={4}>
                                    <div>
                                        <label>Role Name</label>
                                        <Form.Control type="text" />
                                    </div>
                                </Col>
                                <Col md={8}>
                                    <div>
                                        <label>Role Description</label>
                                        <Form.Control type="text" />
                                    </div>
                                </Col>
                            </Row>
                            <Row className="className='add-role-table-div">
                                <h6 className="pt-3 fw-bold">Assign Permissions to Modules</h6>
                                <TableContainer component={Paper} sx={{ maxHeight: 440 }} className='table-container add-role-table-container' style={{ boxShadow: "none" }}>
                                    <Table stickyHeader>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell className='table-header-div'>Name</TableCell>
                                                <TableCell align="center" className='table-header-div'>View</TableCell>
                                                <TableCell align="center" className='table-header-div'>Create</TableCell>
                                                <TableCell align="center" className='table-header-div'>Update</TableCell>
                                                <TableCell align="center" className='table-header-div'>Financial Approval</TableCell>
                                                <TableCell align="center" className='table-header-div'>Transactional Approval</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {modules.map((module, index) => (
                                                <TableRow key={index}>
                                                    <TableCell>{module.name}</TableCell>
                                                    <TableCell align="center">
                                                        <CustomCheckbox
                                                            checked={module.view}
                                                            onChange={() => handleCheckboxChange(index, 'view')}
                                                        />
                                                    </TableCell>
                                                    <TableCell align="center">
                                                        <CustomCheckbox
                                                            checked={module.create}
                                                            onChange={() => handleCheckboxChange(index, 'create')}
                                                        />
                                                    </TableCell>
                                                    <TableCell align="center">
                                                        <CustomCheckbox
                                                            checked={module.update}
                                                            onChange={() => handleCheckboxChange(index, 'update')}
                                                        />
                                                    </TableCell>
                                                    <TableCell align="center">
                                                        <CustomCheckbox
                                                            checked={module.financialApproval}
                                                            onChange={() => handleCheckboxChange(index, 'financialApproval')}
                                                        />
                                                    </TableCell>
                                                    <TableCell align="center">
                                                        <CustomCheckbox
                                                            checked={module.transactionalApproval}
                                                            onChange={() => handleCheckboxChange(index, 'transactionalApproval')}
                                                        />
                                                    </TableCell>
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Row>
                            <Row>
                                <Col md={4}>
                                    <div>
                                        <label>Enable / Disable</label>
                                        <Select
                                            className="react-select-container"
                                            classNamePrefix="react-select"
                                            options={enablingOptions}
                                            placeholder="Select"
                                        />
                                    </div>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Modal.Body>
                <Modal.Footer>
                    <SbCancelBtn btnName="Cancel" onClickEffect={props.handleCloseModal} />
                    <SBSaveUpdateBtn btnName="Submit" onClickEffect={props.handleNextStep} />
                </Modal.Footer>
            </Modal>
        </>
    );
}

export default AddRoles;
