// import React from 'react';
// import { Modal, Button } from 'react-bootstrap';
// import SortIcon from '../../assets/images/Dashboard/chevron-down.svg';
// import SbCancelBtn, { SBSaveUpdateBtn } from './Buttons';

// const StatusBadgeWithDropdown = ({ customer }) => {
//     const [showModal, setShowModal] = React.useState(false);
//     const [modalText, setModalText] = React.useState('');
//     const [selectedStatus, setSelectedStatus] = React.useState(customer.status);

//     const handleStatusChange = (status) => {
//         setModalText(`Do you want to ${status.toLowerCase()} this department?`);
//         setSelectedStatus(status);
//         setShowModal(true);
//     };

//     const handleCloseModal = () => {
//         setShowModal(false);
//     };

//     const handleConfirmChange = () => {
//         // Implement the logic for updating the status
//         console.log(`Status changed to: ${selectedStatus}`);
//         setShowModal(false);
//     };

//     return (
//         <div className="status-badge-with-dropdown" style={{ position: 'relative', display: 'inline-block' }}>
//             <span
//                 className={`status-badge-for-enabling ${selectedStatus === 'Enable' ? 'complete-status' : 'cancel-status'}`}
//             >
//                 {selectedStatus}
//                 <span className="dropdown-arrow" data-bs-toggle="dropdown"
//                     aria-expanded="false">
//                     <img src={SortIcon} alt="dropdown icon" />
//                 </span>
//                 <ul className="dropdown-menu" aria-labelledby="statusDropdown">
//                     <li>
//                         <button
//                             className="dropdown-item"
//                             onClick={() => handleStatusChange('Enable')}
//                         >
//                             Enable
//                         </button>
//                     </li>
//                     <li>
//                         <button
//                             className="dropdown-item"
//                             onClick={() => handleStatusChange('Disable')}
//                         >
//                             Disable
//                         </button>
//                     </li>
//                 </ul>
//             </span>
//             <Modal show={showModal} onHide={handleCloseModal}>
//                 <Modal.Header closeButton>
//                     <Modal.Title>Confirmation</Modal.Title>
//                 </Modal.Header>
//                 <Modal.Body>
//                     {modalText}
//                 </Modal.Body>
//                 <Modal.Footer>
//                     <SbCancelBtn btnName="No" onClickEffect={handleCloseModal} />
//                     <SBSaveUpdateBtn btnName="Yes" onClickEffect={handleConfirmChange} />

//                 </Modal.Footer>
//             </Modal>
//         </div>
//     );
// };

// export default StatusBadgeWithDropdown;


import React from 'react';
import { Modal } from 'react-bootstrap';
import SortIcon from '../../assets/images/Dashboard/chevron-down.svg';
import SbCancelBtn, { SBSaveUpdateBtn } from './Buttons';

const StatusBadgeWithDropdown = ({ 
    customer, 
    activeText = 'Active', 
    inactiveText = 'Inactive',
    confirmModalTitle = 'Confirmation',
    confirmModalBody = 'Do you want to change the status?',
    cancelButtonText = 'No',
    confirmButtonText = 'Yes'
}) => {
    const [showModal, setShowModal] = React.useState(false);
    const [modalText, setModalText] = React.useState('');
    const [selectedStatus, setSelectedStatus] = React.useState(customer.status);

    const handleStatusChange = (status) => {
        setModalText(`Do you want to ${status.toLowerCase()} this status?`);
        setSelectedStatus(status);
        setShowModal(true);
    };

    const handleCloseModal = () => {
        setShowModal(false);
    };

    const handleConfirmChange = () => {
        // Implement the logic for updating the status
        console.log(`Status changed to: ${selectedStatus}`);
        setShowModal(false);
    };

    return (
        <div className="status-badge-with-dropdown" style={{ position: 'relative', display: 'inline-block' }}>
            <span
                className={`status-badge-for-enabling ${selectedStatus === activeText ? 'complete-status' : 'cancel-status'}`}
            >
                {selectedStatus}
                <span className="dropdown-arrow" data-bs-toggle="dropdown" aria-expanded="false">
                    <img src={SortIcon} alt="dropdown icon" />
                </span>
                <ul className="dropdown-menu" aria-labelledby="statusDropdown">
                    <li>
                        <button
                            className="dropdown-item"
                            onClick={() => handleStatusChange(activeText)}
                        >
                            {activeText}
                        </button>
                    </li>
                    <li>
                        <button
                            className="dropdown-item"
                            onClick={() => handleStatusChange(inactiveText)}
                        >
                            {inactiveText}
                        </button>
                    </li>
                </ul>
            </span>
            <Modal show={showModal} onHide={handleCloseModal}>
                <Modal.Header closeButton>
                    <Modal.Title>{confirmModalTitle}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {modalText || confirmModalBody}
                </Modal.Body>
                <Modal.Footer>
                    <SbCancelBtn btnName={cancelButtonText} onClickEffect={handleCloseModal} />
                    <SBSaveUpdateBtn btnName={confirmButtonText} onClickEffect={handleConfirmChange} />
                </Modal.Footer>
            </Modal>
        </div>
    );
};

export default StatusBadgeWithDropdown;

